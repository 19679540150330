import React, { useContext } from "react";
import moment from "moment";
import { Input } from "reactstrap";
import TbdCheckBox from "../tbdFormComponents/TbdCheckBox/TbdCheckBox";
import DistrictCheckBox from "../tbdViews/signature/DistrictCheckBox";
//import { getRegOptions, initThisRegion } from "../services/signatureService";
//import { initDistrict } from "../services/signatureService";

const CurrentMonthOptions = props => {
  let monthArray = [];

  for (let count = 0; count < 11; count++) {
    monthArray.push(
      <option
        key={count}
        value={parseInt(
          moment()
            .add(count, "months")
            .format("M")
        )}
      >
        {moment()
          .add(count, "months")
          .format("MMMM")}
      </option>
    );
  }
  return monthArray;
};

const TbdRegistrySidebarSearch = props => {
  const {
    countries,
    country,
    regions,
    region,
    districts,
    district,
    setCountry
  } = props;
  //
  //  function initRegion() {
  //  getRegOptions({ country, region, district }).then(res =>
  //    props.setRegions(res.values)
  //  );
  //  }

  // async function handleCountryChange(e) {
  //   const { value } = e.target;

  //setCountry(value);

  //  if (value !== "ALL") {
  //    const regionList = await initThisRegion(value);

  //    props.setRegions(regionList);
  //  }

  //  return;
  //setCountry(value);
  //const regionList = initDistrict("Texas");
  //initRegion();
  //props.setRegions(regionList);
  // }

  //  function handleRegionChange(e) {
  //const { value } = e.taget;
  //console.log(value);
  //    props.setRegion(e.target.value);
  //  }
  /*
  const districtOptions = districts.map(val => {
    return (
      <option key={val} value={val}>
        {val}
      </option>
    );
  });
**/

  return (
    <aside class="col-lg-3">
      {/** 
      <p>
        <a
          class="btn_map"
          data-toggle="collapse"
          href="#collapseMap"
          aria-expanded="false"
          aria-controls="collapseMap"
          data-text-swap="Hide map"
          data-text-original="View on map"
        >
          View on map
        </a>
      </p>
*/}
      <div id="filters_col">
        <a
          data-toggle="collapse"
          href="#collapseFilters"
          aria-expanded="false"
          aria-controls="collapseFilters"
          id="filters_col_bt"
        >
          <i class="icon_set_1_icon-65"></i>Filters
        </a>
        <div class="collapse show" id="collapseFilters">
          <div class="filter_type">
            <h6>Location</h6>
            <div class="form-group">
              <label>Preferred travel area</label>
              <Input
                type="select"
                //    onChange={handleCountryChange}
                value={country}
                placeholder={"FILTER BY LOCATION"}
              >
                {countries.map(val => {
                  return (
                    <option key={val} value={val}>
                      {val}{" "}
                    </option>
                  );
                })}
                <option value={"ALL"}>ALL</option>
              </Input>
            </div>
          </div>

          <div class="filter_type">
            <h6>Region</h6>
            <ul>
              {country !== "ALL" && (
                <li>
                  <div class="form-group">
                    <label>Preferred travel area</label>
                    <Input
                      type="select"
                      //onChange={e => handleRegionChange(e)}
                      value={region}
                      placeholder={"FILTER BY REGION"}
                    >
                      {regions.map(val => {
                        return (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        );
                      })}
                      <option value={"ALL"}>ALL</option>
                    </Input>
                  </div>
                </li>
              )}
            </ul>
          </div>

          <div class="filter_type">
            <h6>District</h6>
            <ul>
              {region !== "ALL"}
              <React.Frament>
                {districts.map(district => {
                  return (
                    <li key={district}>
                      <DistrictCheckBox name={"district"} />
                    </li>
                  );
                })}
              </React.Frament>
            </ul>
          </div>

          <div class="filter_type">
            <h6>Other</h6>
            <ul>
              <li>
                <TbdCheckBox
                  label={"Include VIP Nights"}
                  disabled={false}
                  item={"vipBoxValue"}
                  pressedColor={"#1d8488"}
                  //    onValueChange={() => console.log()}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="box_style_2">
        <i class="icon_set_1_icon-57"></i>
        <h4>
          Need <span>Help?</span>
        </h4>
        <a href="tel://004542344599" class="phone">
          +1-800-999-9999
        </a>
        <small>Monday to Friday 9.00am - 7.30pm</small>
      </div>
    </aside>
  );
};

export default TbdRegistrySidebarSearch;
