import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const TbeAvailableResort = props => {
  return (
    <div className="strip_all_tour_list wow fadeIn" data-wow-delay="0.1s">
      <div className="row">
        <div className="col-lg-4 col-md-4">
          {props.promoRibbon && (
            <div
              className={`ribbon_3 ${
                props.promoRibbon === "red" ? "popular" : ""
              }`}
            >
              <span>{props.promoRibbonText}</span>
            </div>
          )}

          <div className="wishlist">
            <Link
              className="tooltip_flip tooltip-effect-1"
              href="javascript:void(0);"
            >
              +
              <span className="tooltip-content-flip">
                <span className="tooltip-back">Add to wishlist</span>
              </span>
            </Link>
          </div>
          <div className="img_list">
            <Link to={`/signature/resort/${props.unit._id}`}>
              <img src={props.img} alt={props.resortName}></img>
              <div className="short_info"></div>
            </Link>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="tour_list_desc ">
            <h3>
              <strong>
                {props.unit.unitSize <= 0
                  ? "Studio"
                  : `${props.unit.unitSize} Bedroom`}
              </strong>
            </h3>
            <p className="mb-md-0">{`${moment(props.unit.startDate).format(
              "dddd"
            )} Check-In`}</p>
            <p className="mb-md-0 mt-3">{`Check in Time: ${moment(
              props.checkIn.replace("Check in Time:", "").trim(),
              "H:mm"
            ).format("h:mm a")}`}</p>
            <p className="mb-md-0 mt-1">{`Check out Time: ${moment(
              props.checkOut.replace("Check out Time:", "").trim(),
              "H:mm"
            ).format("h:mm a")}`}</p>
            <hr></hr>
            <h6>
              <span>Availability During:</span>
            </h6>
            <p>
              <i className="icon-calendar-empty"></i>
              <span>
                {moment(props.unit.startDate).format("MMM Do YYYY")}
              </span>{" "}
              - <i className="icon-calendar-empty"></i>
              <span>{moment(props.unit.endDate).format("MMM Do YYYY")}</span>
            </p>
          </div>
        </div>
        <div className="col-lg-2 col-md-2">
          <div className="price_list">
            <div>
              <sup>$</sup>
              {Number(props.unit.fullPrice).toFixed(0)}*
              <span className="normal_price_list">
                ${Number(props.unit.fullPrice / 0.35).toFixed(0)}
              </span>
              <small>*From/Per week</small>
              <p>
                <Link
                  //to={`/signature/resort/${props.unit._id}`}
                  className="btn_1"
                >
                  Details
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TbeAvailableResort;
