import React, { useReducer, useEffect, useContext, useState } from "react";
import {
  searchSignatureCollection,
  getOptions,
  getSigCount,
  getSigOptions
} from "../../services/signatureService";
//import TbdBanner from "../../tbdPageComponents/tbdSignaturePage/TbdBanner";
import TbdBreadcrumbHeader from "../../tbdCommon/TbdBreadcrumbHeader";
import TbdSignatureSidebarSearch from "../../tbdPageComponents/tbdSignaturePage/TbdSignatureSidebarSearch";
import TbdSignatureSortToolsHeader from "../../tbdPageComponents/tbdSignaturePage/TbdSignatureSortToolsHeader";
import TbdSignatureCountryHeader from "../../tbdPageComponents/tbdSignaturePage/TbdSignatureCountryHeader";
import {
  SignatureContext,
  TbdContext,
  useSearcher,
  reducer
} from "../../data/TbdContext";
import moment from "moment";
import queryString from "query-string";
import TbdPagination from "../../tbdCommon/TbdPagination";
import TbdParallaxBanner from "../../tbdPageComponents/tbdSignaturePage/TbdParallaxBanner";

const TbdSignature = props => {
  const values = queryString.parse(props.location.search);
  const { tbdDispatch } = useContext(TbdContext);
  //Onload scroll to top
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [
    {
      areaToRequest,
      page,
      month,
      unitSize,
      country,
      vipBoxValue,
      areas,
      destinations,
      regions,
      region,
      districts,
      district,
      resorts,
      count,
      pageCount,
      list,
      showableResorts,
      sortBy,
      sortByDirection
    },
    signatureDispatch
  ] = useReducer(reducer, {
    areaToRequest: "country",
    page: 1,
    month: "ALL",
    unitSize: -1,
    country: values.country ? values.country : "ALL",
    vipBoxValue: false,
    areas: [],
    destinations: [],
    regions: [],
    region: values.region ? values.region : "ALL",
    districts: [],
    district: "ALL", //values.district ? values.district : "ALL",
    resorts: [],
    count: 0,
    pageData: [],
    pageCount: 0,
    showableResorts: [],
    list: "area",
    sortBy: "location",
    sortByDirection: 1
  });

  //  useEffect(() => {
  //    if (values.country) {
  //      signatureDispatch({
  //        type: useSearcher.types.setCountry,
  //        payload: values.country
  //      });
  //    }
  //  }, [values.country]);

  //NEW INIT API CALL
  //  useEffect(() => {
  //    tbdDispatch({ type: "setLoading", payload: true });
  //    searchSignatureCollection({
  //      month,
  //      unitSize,
  //      page,
  //      country,
  //      region,
  //      district,
  //      vipBoxValue,
  //      areaToRequest,
  //      sortBy,
  //      sortByDirection
  //    }).then(results => {
  //      signatureDispatch({
  //        type: useSearcher.types.setResorts,
  //        payload: results.resorts
  //      });
  //      signatureDispatch({
  //        type: useSearcher.types.setCount,
  //        payload: results.count
  //      });
  //    }, tbdDispatch({ type: "setLoading", payload: false }));
  //  }, [
  //    month,
  //    unitSize,
  //    country,
  //    region,
  //    district,
  //    page,
  //    vipBoxValue,
  //    sortBy,
  //    sortByDirection,
  //    tbdDispatch,
  //    areaToRequest
  //  ]);

  //  useEffect(() => {
  //    signatureDispatch({
  //      type: useSearcher.types.setPageCount,
  //      payload: Math.ceil(count / 25)
  //    });
  //  }, [count]);

  //  useEffect(() => {
  //    if (country !== "ALL" && region !== "ALL") {
  //      //STOP UPDATING showToResorts
  //    }
  //
  //    signatureDispatch({ type: useSearcher.types.setShowToResorts });
  //    //if (destinations.length === 0) {
  //
  //    //}
  //  }, [country, region, district, unitSize, month, vipBoxValue]);

  //  useEffect(() => {
  //    getOptions({ month, unitSize, country, region, vipBoxValue }).then(
  //      results => {
  //        signatureDispatch({
  //          type: "RELOAD_OPTIONS",
  //          payload: results
  //        });
  //      }
  //    );
  //  }, [country, month, region, unitSize, vipBoxValue]);

  //  console.log(resorts);
  useEffect(() => {
    searchSignatureCollection({
      month,
      unitSize,
      page,
      country,
      region,
      district,
      vipBoxValue,
      sortBy,
      sortByDirection
    }).then(result => {
      console.log(result);
      signatureDispatch({
        type: useSearcher.types.setResorts,
        payload: result
      });
    });
  }, [
    country,
    district,
    month,
    page,
    region,
    sortBy,
    sortByDirection,
    unitSize,
    vipBoxValue
  ]);

  useEffect(() => {
    getSigOptions({
      month,
      unitSize,
      country,
      region,
      vipBoxValue
    }).then(result => {
      if (region !== "ALL") {
        console.log("districts", result);
        signatureDispatch({
          type: "SET_DISTRICTS",
          payload: result
        });
      } else if (country !== "ALL") {
        console.log("regions", result);
        signatureDispatch({
          type: "SET_REGIONS",
          payload: result
        });
      } else {
        console.log("countries", result);
        signatureDispatch({
          type: "SET_COUNTRIES",
          payload: result
        });
      }
    });
  }, [month, unitSize, country, region, vipBoxValue]);

  function handleDistrictChange(e) {
    if (e.value === false) {
      //const newPageData = pageData.filter(resort => {
      //  return resort.district !== e.item;
      //});
      //setPageData(newPageData);
      console.log("false", e);
    } else {
      console.log("true", e);
      //Clean data first
      //const newPageData = pageData.filter(resort => {
      //  return resort.district !== e.item;
      //});
      ////Add from Store
      //const addedData = store.filter(resort => {
      //  return resort.district === e.item;
      //});
      //
      //setPageData([...addedData, ...newPageData]);
      //setCount(pageData.length);
    }
  }
  return (
    <SignatureContext.Provider
      value={{
        page,
        month,
        unitSize,
        country,
        vipBoxValue,
        useSearcher,
        areas,
        destinations,
        regions,
        region,
        district,
        districts,
        signatureDispatch,
        resorts,
        count,
        pageCount,
        areaToRequest,
        sortBy,
        sortByDirection
      }}
    >
      <TbdParallaxBanner
        signatureCount={Math.floor(Math.random() * (10125 - 10103 + 1)) + 10103}
      />
      <main>
        <TbdBreadcrumbHeader />
        <div className="collapse" id="collapseMap">
          <div id="map" className="map"></div>
        </div>
        <div className="container margin_60">
          <div className="row">
            <TbdSignatureSidebarSearch
              handleDistrictChange={handleDistrictChange}
            />
            <div className="col-lg-9">
              <TbdSignatureSortToolsHeader
                dispatch={signatureDispatch}
                sortBy={sortBy}
                sortByDirection={sortByDirection}
              />
              <TbdPagination />

              {}
              {resorts.map(destination => {
                console.log(destination);
                return (
                  <TbdSignatureCountryHeader
                    dispatch={signatureDispatch}
                    key={destination._id}
                    country={destination._id}
                    resorts={destination.resorts}
                    areaToRequest={areaToRequest}
                    searchedCountry={country}
                    searchedRegion={region}
                    sortBy={sortBy}
                  />
                );
              })}
              <hr></hr>

              <TbdPagination />
            </div>
          </div>
        </div>
      </main>
    </SignatureContext.Provider>
  );
};

export default TbdSignature;
