import React from "react";
import TbdDesignCenterBanner from "../../tbdPageComponents/tbdDesignPage/TbdDesignCenterBanner";
import getDcPlatforms from "../../data/DesignCenterData";

const TbdDesignCenter = props => {
  const DcData = getDcPlatforms();
  return (
    <main>
      <TbdDesignCenterBanner />
      <div class="container margin_60">
        <div class="main_title">
          <h2>
            Design <span>Center</span> Platforms
          </h2>
          <p>Design your travel around the things you love to do</p>
        </div>

        <div class="row">
          {DcData.map((platform, index) => {
            return (
              <div
                class="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay={`0.${index + 1}s`}
              >
                <div class="tour_container">
                  <div class="img_container">
                    <a
                      href={"/design"}
                      onClick={() =>
                        window.open(platform.link, "designcenterwindow")
                      }
                    >
                      <img
                        src={platform.img}
                        width="800"
                        height="533"
                        class="img-fluid"
                        alt={platform.name}
                      ></img>
                      <div class="short_info">
                        <i class="icon_set_1_icon-44"></i>
                        {platform.description}
                        <span class="price">
                          <sup></sup>
                          {platform.name}
                        </span>
                      </div>
                    </a>
                  </div>
                  <div class="tour_title">
                    <h3>
                      <strong>{platform.name}</strong> <p>{platform.filter}</p>
                    </h3>

                    <div class="wishlist">
                      <a class="tooltip_flip tooltip-effect-1" href="/design">
                        +
                        <span class="tooltip-content-flip">
                          <span class="tooltip-back">Add to wishlist</span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </main>
  );
};

export default TbdDesignCenter;
