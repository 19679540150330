import React from "react";

import TbdDefault from "./layouts/TbdDefault";
//import TbdSignature from "./tbdViews/signature/TbdSignature";
import ResortPage from "./tbdViews/resort/ResortPage";
import TbdRegistry from "./tbdViews/registry/TbdRegistry";
import DevWindow from "./tbdViews/DevWindow/DevWindow";
//import TbdRegistry from "./tbdPageComponents/TbdRegistryPage/TbdRegistry";
import TbdSignature from "./tbdViews/signatureCollection/TbdSignature";
import TbdDesignCenter from "./tbdViews/designCenter/TbdDesignCenter";
import TbdCruise from "./tbdViews/cruise/TbdCruise";
import TbdNewClientContract from "./tbdViews/NewClient/TbdNewClientContract";
import TbdAddClientContract from "./tbdViews/NewClient/TbdAddClientContract";
import ViewClient from "./tbdViews/ViewClient/ViewClient";

export default [
  {
    path: "/dec",
    layout: TbdDefault,
    component: "DevCenter",
    main: <h4>Dev Center</h4>
  },
  {
    path: "/built",
    component: DevWindow
  },
  {
    path: "/signature",
    layout: TbdDefault,
    component: TbdSignature,
    exact: true
  },
  {
    path: "/signature/resort/:id",
    layout: TbdDefault,
    component: ResortPage,
    exact: true,
    main: <h4>Signature Resort</h4>
  },
  {
    path: "/registry",
    layout: TbdDefault,
    component: TbdRegistry,
    exact: true,
    main: <h4>Signature Registry</h4>
  },
  {
    path: "/registry/resort/:id",
    layout: TbdDefault,
    component: ResortPage,
    exact: true,
    main: <h4>Registry Resort</h4>
  },
  {
    path: "/design",
    layout: TbdDefault,
    component: TbdDesignCenter,
    exact: true,
    main: <h4>Design Center</h4>
  },
  {
    path: "/cruises",
    layout: TbdDefault,
    component: TbdCruise,
    exact: true,
    main: <h4>Cruises</h4>
  },
  {
    path: "/newclient",
    layout: TbdDefault,
    component: TbdAddClientContract,
    exact: true,
    main: <h4>New Client</h4>
  },
  {
    path: "/client/view/:id",
    layout: TbdDefault,
    component: ViewClient,
    exact: true,
    main: <h4>View Client</h4>
  }
];
