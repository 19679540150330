import React, { useContext } from "react";
import TbdHeader from "../tbdCommon/TbdHeader";
import TbdFooter from "../tbdCommon/TbdFooter";
import TbdToTop from "../tbdCommon/TbdToTop";
import TbdSearchMenu from "../tbdCommon/TbdSearchMenu";
import { TbdContext } from "../data/TbdContext";
import TbdSignInModal from "../tbdCommon/TbdSignInModal";
import TbdPreLoader from "../tbdCommon/TbdPreLoader";
import TbdHeaderDEMO from "../tbdCommon/TbdHeaderDEMO";

const TbdDefault = props => {
  const { hasFullscreenModal, isLoading } = useContext(TbdContext);

  const children = React.Children.map(props.children, child => {
    return React.cloneElement(child, {
      something: "here"
    });
  });

  return (
    <body className={hasFullscreenModal === true ? "has-fullscreen-modal" : ""}>
      <TbdPreLoader switch={isLoading === true ? 1 : 0} />
      <div className="layer"></div>
      <TbdHeaderDEMO />
      {children};
      <TbdFooter />
      <TbdToTop />
      <TbdSearchMenu />
      <TbdSignInModal />
    </body>
  );
};

export default TbdDefault;
