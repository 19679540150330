import React, { useState, useEffect } from "react";
import TheCruiseBanner from "../../tbdPageComponents/TbdCruisesPage/TbdCruiseBanner";
import { searchCruises } from "../../services/serverCallsService";
import moment from "moment";

const TbdCruise = () => {
  const [cruises, setCruises] = useState(null);
  const [cruiseColOne, setCruiseColOne] = useState(null);
  const [cruiseColTwo, setCruiseColTwo] = useState(null);
  const [cruiseRows, setCruiseRows] = useState([]);

  useEffect(() => {
    searchCruises()
      .then(cruisesShips => {
        setCruises(cruisesShips);
        return cruisesShips;
      })
      .then(ships => {
        let cruiseR = [];
        ships.map((ship, index) => {
          if (index % 2) {
            cruiseR.push([ships[index - 1], ships[index]]);
          }
        });
        setCruiseRows(cruiseR);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <main>
      <TheCruiseBanner />
      <div class="container margin_60">
        <div class="row">
          <aside class="col-lg-3">
            {/** 
            <div class="box_style_cat">
              <ul id="cat_nav">
                <li>
                  <a href="#" id="active">
                    <i class="icon_set_1_icon-51"></i>All tours{" "}
                    <span>(141)</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="icon_set_1_icon-3"></i>City sightseeing{" "}
                    <span>(20)</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="icon_set_1_icon-4"></i>Museum tours{" "}
                    <span>(16)</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="icon_set_1_icon-44"></i>Historic Buildings{" "}
                    <span>(12)</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="icon_set_1_icon-37"></i>Walking tours{" "}
                    <span>(11)</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="icon_set_1_icon-14"></i>Eat & Drink{" "}
                    <span>(20)</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="icon_set_1_icon-43"></i>Churces <span>(08)</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="icon_set_1_icon-28"></i>Skyline tours{" "}
                    <span>(11)</span>
                  </a>
                </li>
              </ul>
            </div>
*/}
            <div class="box_style_2">
              <i class="icon_set_1_icon-57"></i>
              <h4>
                Need <span>Help?</span>
              </h4>
              <a href="tel://17739853001" class="phone">
                (773) 985-3001
              </a>
              <small>Monday to Friday 9.00am - 7.30pm</small>
            </div>
          </aside>

          <div class="col-lg-9">
            <div id="tools">
              <div class="row">
                {/**
                <div class="col-md-3 col-sm-4 col-6">
                  <div class="styled-select-filters">
                    <select
                      name="sort_price"
                      id="sort_price"
                      data-filter-group="sort_price"
                    >
                      <option value="" selected="">
                        Sort by price
                      </option>
                      <option value="*" data-filter-value="">
                        Show all
                      </option>
                      <option
                        value="lower_price"
                        data-filter-value=".lower_price"
                      >
                        Lowest price
                      </option>
                      <option
                        value="higher_price"
                        data-filter-value=".higher_price"
                      >
                        Higher price
                      </option>
                    </select>
                  </div>
                </div>
            
                <div class="col-md-3 col-sm-4 col-6">
                  <div class="styled-select-filters">
                    <select
                      name="sort_rating"
                      id="sort_rating"
                      data-filter-group="sort_rating"
                    >
                      <option value="" selected>
                        Sort by ranking
                      </option>
                      <option value="*" data-filter-value="">
                        Show all
                      </option>
                      <option
                        value="lower_ranking"
                        data-filter-value=".lower_ranking"
                      >
                        Lowest ranking
                      </option>
                      <option
                        value="higher_ranking"
                        data-filter-value=".higher_ranking"
                      >
                        Highest ranking
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6 col-sm-4 d-none d-sm-block text-right">
                  <a href="#" class="bt_filters">
                    <i class="icon-th"></i>
                  </a>{" "}
                  <a href="#" class="bt_filters">
                    <i class=" icon-list"></i>
                  </a>
                </div>
              */}
              </div>
            </div>

            <div class="isotope-wrapper">
              {cruiseRows.map(row => {
                return (
                  <div class="row grid">
                    <div class="col-md-6 isotope-item lower_price">
                      <div class="tour_container">
                        <div class="ribbon_3 popular">
                          <span>Popular</span>
                        </div>
                        <div class="img_container">
                          <a href="#">
                            <img
                              src={row[0].img}
                              width="800"
                              height="533"
                              class="img-fluid"
                              alt={row[0]._id}
                            ></img>
                            <div class="short_info">
                              <i class="icon_set_1_icon-44"></i>
                              {moment(row[0].startDate).format(
                                "MMM-DD-YYYY"
                              )} -{" "}
                              {moment(row[0].endDate).format("MMM-DD-YYYY")}
                              <span class="price">
                                <sup>$</sup>
                                {row[0].insideRoom}
                              </span>
                            </div>
                          </a>
                        </div>
                        <div class="tour_title">
                          <h3>
                            <strong>{row[0].ship}</strong> {row[0].line}
                          </h3>

                          <h3>
                            {moment(row[0].endDate).diff(
                              moment(row[0].startDate),
                              "days"
                            ) + " Days"}
                          </h3>
                          <div class="wishlist">
                            <a class="tooltip_flip tooltip-effect-1" href="#">
                              +
                              <span class="tooltip-content-flip">
                                <span class="tooltip-back">
                                  Add to wishlist
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 isotope-item lower_price higher_ranking">
                      <div class="tour_container">
                        <div class="ribbon_3 popular">
                          <span>Popular</span>
                        </div>
                        <div class="img_container">
                          <a href="#">
                            <img
                              src={row[1].img}
                              width="800"
                              height="533"
                              class="img-fluid"
                              alt={row[1]._id}
                            ></img>
                            <div class="short_info">
                              <i class="icon_set_1_icon-44"></i>
                              {moment(row[1].startDate).format(
                                "MMM-DD-YYYY"
                              )} -{" "}
                              {moment(row[1].endDate).format("MMM-DD-YYYY")}
                              <span class="price">
                                <sup>$</sup>
                                {row[1].insideRoom}
                              </span>
                            </div>
                          </a>
                        </div>
                        <div class="tour_title">
                          <h3>
                            <strong>{row[1].ship}</strong> {row[1].line}
                          </h3>

                          <h3>
                            {moment(row[1].endDate).diff(
                              moment(row[1].startDate),
                              "days"
                            ) + " Days"}
                          </h3>
                          <div class="wishlist">
                            <a class="tooltip_flip tooltip-effect-1" href="#">
                              +
                              <span class="tooltip-content-flip">
                                <span class="tooltip-back">
                                  Add to wishlist
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <hr></hr>
            {/**
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">Previous</span>
                  </a>
                </li>
                <li class="page-item active">
                  <span class="page-link">
                    1<span class="sr-only">(current)</span>
                  </span>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </nav>
         */}
          </div>
        </div>
      </div>
    </main>
  );
};

export default TbdCruise;
