//import moment from "moment";
import { db_get, db_post } from "./dbService";
import { cw_get, cw_post } from "./condoWarehouse";
//import { logError } from "../services/logService";

export async function createClient(client) {
  return await db_post(`/newclient/client`, { client });
}

export async function saveClientPayments({
  clientId,
  payments,
  totalDown,
  price,
  additional
}) {
  return await db_post(`/newclient/payment`, {
    clientId,
    payments,
    totalDown,
    price,
    additional
  });
}
