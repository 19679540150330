import React, { useState, useEffect } from "react";
import moment from "moment";
import { getWeather } from "../services/serverCallsService";
import _ from "lodash";
import ColdWeather from "../assets/img/weather/weather-cold-web.jpg";
import CoolWeather from "../assets/img/weather/weather-cool-web.jpg";
import WarmWeather from "../assets/img/weather/weather-warm-web.jpg";
import BgWeather from "../assets/img/cities/weather-bg.jpg";

const WeatherWidget = props => {
  const [weatherImg, setWeatherImg] = useState(BgWeather);
  const [weatherData, setWeatherData] = useState({
    current: {
      weather: [
        {
          icon: "01d"
        }
      ],
      main: {
        temp: "0"
      }
    },
    forcast: {
      list: []
    }
  });
  useEffect(() => {
    getWeather(props.id).then(setWeatherData);
  }, [props.id]);

  useEffect(() => {
    const temp = Math.ceil(parseInt(weatherData.current.main.temp));

    if (temp < 32) {
      setWeatherImg(ColdWeather);
    } else if (temp < 60) {
      setWeatherImg(CoolWeather);
    } else if (temp < 75) {
      setWeatherImg(WarmWeather);
    } else {
      setWeatherImg(BgWeather);
    }
  }, [weatherData]);

  const forcastArray = weatherData.forcast.list.map(day => {
    return (
      <div key={day.dt} className="col-4">
        <ul className="list-unstyled mb-0 text-center">
          <li className="mb-1">
            <p className="text-white mb-0">
              {moment(day.dt, "X").format("ddd")}
            </p>
          </li>

          <li className="mb-1">
            <img
              src={
                process.env.PUBLIC_URL +
                `/assets/icons/${day.weather[0].icon}.png`
              }
              alt="day"
            ></img>
          </li>

          <li>
            <p className="text-white mb-0">
              {Math.ceil(parseInt(day.temp.day))}
              <sup>0</sup>
            </p>
          </li>
        </ul>
      </div>
    );
  });

  const forcast = _.slice(forcastArray, 1, 4);
  const { resort } = props;

  return (
    <div className="card card-bg-img shadow">
      <div className="position-relative ">
        <img
          className="card-img-top rounded-0"
          src={weatherImg}
          alt="day"
          style={{ height: 450 + "px" }}
        ></img>

        <div
          className={`card-img-overlay rounded-0 d-flex flex-column fade-in ${
            forcastArray.length > 0 ? "show" : ""
          }`}
        >
          <div className="row">
            <div className="col-6">
              <p className="text-white">{moment().format("LL")}</p>
            </div>

            <div className="col-6">
              <div className="pull-right">
                <p className="text-white">{moment().format("dddd")}</p>
              </div>
            </div>
          </div>

          <div className="mt-auto text-center">
            <div className="mb-2">
              <img
                className="img-fluid"
                src={
                  process.env.PUBLIC_URL +
                  `/assets/icons/${weatherData.current.weather[0].icon}.png`
                }
                alt="day"
              ></img>
            </div>

            <h2 className="text-white font-weight-bold display-4 mb-4">
              {Math.ceil(parseInt(weatherData.current.main.temp))}
              <sup>0</sup>
            </h2>
            <h3 className="text-white font-weight-bold mb-0">
              {`${props.area.region !== null ? props.area.region + ", " : ""} ${
                props.area.country
              }`}
            </h3>
          </div>

          <div className="mt-auto px-4 px-lg-0 px-xl-4">
            <div className="row">{forcast}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeatherWidget;
