import React from "react";
import { Input } from "reactstrap";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { formatDate, parseDate } from "react-day-picker/moment";

const TbdNewAddPaymentForm = props => {
  return (
    <form onSubmit={props.handleSavePayments}>
      <div className="container margin_60">
        <div className="row">
          <div className="col-lg-8 add_bottom_15">
            <div className="form_title">
              <h3>
                <strong>1</strong>Payment 1
              </h3>
              <p>Downpayment 1</p>
            </div>
            <div className="step">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <p>Payment Date</p>
                    <DayPickerInput
                      formatDate={formatDate}
                      parseDate={parseDate}
                      format="MM/DD/YYYY"
                      placeholder={`${formatDate(new Date(), "MM/DD/YYYY")}`}
                      onDayChange={day => props.setPayment1Date(day)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-9">
                  <div className="form-group">
                    <label>Billing name</label>
                    <input
                      ref={props.payment1Name}
                      type="text"
                      className="form-control"
                      id="firstname_booking"
                      name="firstname_booking"
                      required
                    ></input>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label>Amount</label>
                    <input
                      value={props.downpayment}
                      onChange={e => props.setDownpayment(e.target.value)}
                      type="text"
                      className="form-control"
                      id="lastname_booking"
                      name="downpayment_booking"
                      required
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Card Type</label>
                    <select
                      ref={props.paymentMethodRef}
                      className="form-control"
                      name="cardType"
                      id="cardType"
                    >
                      <option value="" selected>
                        Select Card Type
                      </option>
                      <option value="Visa">Visa</option>
                      <option value="Master Card">Master Card</option>
                      <option value="Amex">Amex</option>
                      <option value="Discover">Discover</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-9">
                  <div className="form-group">
                    <label>Card #</label>
                    <input
                      ref={props.payment1CardRef}
                      type="text"
                      id="cardnumber"
                      name="cardnumber"
                      className="form-control"
                      required
                    ></input>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label>Code</label>
                    <input
                      ref={props.payment1CodeRef}
                      type="text"
                      id="cvv"
                      name="cvv"
                      className="form-control"
                      required
                    ></input>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Country</label>
                    <select
                      ref={props.payment1countryRef}
                      className="form-control"
                      name="country"
                      id="country"
                    >
                      <option value="" selected>
                        Select your country
                      </option>
                      <option value="Europe">Europe</option>
                      <option value="United states">United states</option>
                      <option value="Asia">Asia</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Street line 1</label>
                    <input
                      ref={props.payment1addressRef}
                      type="text"
                      id="street_1"
                      name="street_1"
                      className="form-control"
                      required
                    ></input>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Street line 2</label>
                    <input
                      ref={props.payment1address2Ref}
                      type="text"
                      id="street_2"
                      name="street_2"
                      className="form-control"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label>City</label>
                    <input
                      ref={props.payment1cityRef}
                      type="text"
                      id="city_booking"
                      name="city_booking"
                      className="form-control"
                      required
                    ></input>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="form-group">
                    <label>State</label>
                    <input
                      ref={props.payment1stateRef}
                      type="text"
                      id="state_booking"
                      name="state_booking"
                      className="form-control"
                      required
                    ></input>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="form-group">
                    <label>Postal code</label>
                    <input
                      ref={props.payment1zipRef}
                      type="text"
                      id="postal_code"
                      name="postal_code"
                      className="form-control"
                      required
                    ></input>
                  </div>
                </div>
              </div>
            </div>

            <div className="form_title">
              <h3>
                <strong>2</strong>Add Payment 2?
              </h3>
              <label className="switch-light switch-ios float-right">
                <input
                  type="checkbox"
                  name="option_5"
                  id="option_5"
                  checked={props.getDown2}
                  onClick={() => props.setGetDown2(getDown2 => !getDown2)}
                ></input>
                <span>
                  <span>No</span>
                  <span>Yes</span>
                </span>
                <a></a>
              </label>
              <p>Click the switch to the right</p>
            </div>
            {props.getDown2 && (
              <div className="step">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <p>Payment Date</p>
                      <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="MM/DD/YYYY"
                        placeholder={`${formatDate(new Date(), "MM/DD/YYYY")}`}
                        onDayChange={day => props.setPayment2Date(day)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-9">
                    <div className="form-group">
                      <label>Billing name</label>
                      <input
                        ref={props.payment2Name}
                        type="text"
                        className="form-control"
                        id="firstname_booking2"
                        name="firstname_booking2"
                        required={props.getDown2 ? true : false}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Amount</label>
                      <input
                        type="text"
                        className="form-control"
                        id="downpayment_booking2"
                        name="downpayment_booking2"
                        value={props.downpayment2}
                        onChange={e => props.setDownpayment2(e.target.value)}
                        required={props.getDown2 ? true : false}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Card Type</label>
                      <select
                        ref={props.paymentMethod2Ref}
                        className="form-control"
                        name="cardType2"
                        id="cardType2"
                      >
                        <option value="" selected>
                          Select Card Type
                        </option>
                        <option value="Visa">Visa</option>
                        <option value="Master Card">Master Card</option>
                        <option value="Amex">Amex</option>
                        <option value="Discover">Discover</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-9">
                    <div className="form-group">
                      <label>Card #</label>
                      <input
                        ref={props.payment2CardRef}
                        type="text"
                        id="cardnumber2"
                        name="cardnumber2"
                        className="form-control"
                        required={props.getDown2 ? true : false}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Code</label>
                      <input
                        ref={props.payment2CodeRef}
                        type="text"
                        id="cvv2"
                        name="cvv2"
                        className="form-control"
                        required={props.getDown2 ? true : false}
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Country</label>
                      <select
                        ref={props.payment2countryRef}
                        className="form-control"
                        name="country2"
                        id="country2"
                      >
                        <option value="" selected>
                          Select your country
                        </option>
                        <option value="Europe">Europe</option>
                        <option value="United states">United states</option>
                        <option value="Asia">Asia</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Street line 1</label>
                      <input
                        ref={props.payment2addressRef}
                        type="text"
                        id="street_12"
                        name="street_12"
                        className="form-control"
                        required={props.getDown2 ? true : false}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Street line 2</label>
                      <input
                        ref={props.payment2address2Ref}
                        type="text"
                        id="street_12"
                        name="street_12"
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <label>City</label>
                      <input
                        ref={props.payment2cityRef}
                        type="text"
                        id="city_booking2"
                        name="city_booking2"
                        className="form-control"
                        required={props.getDown2 ? true : false}
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="form-group">
                      <label>State</label>
                      <input
                        ref={props.payment2stateRef}
                        type="text"
                        id="state_booking2"
                        name="state_booking2"
                        className="form-control"
                        required={props.getDown2 ? true : false}
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="form-group">
                      <label>Postal code</label>
                      <input
                        ref={props.payment2zipRef}
                        type="text"
                        id="postal_code2"
                        name="postal_code2"
                        className="form-control"
                        required={props.getDown2 ? true : false}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="form_title">
              <h3>
                <strong>3</strong>Add Payment 3?
              </h3>
              <label className="switch-light switch-ios float-right">
                <input
                  type="checkbox"
                  name="option_5"
                  id="option_5"
                  checked={props.getDown3}
                  onClick={() => props.setGetDown3(getDown3 => !getDown3)}
                ></input>
                <span>
                  <span>No</span>
                  <span>Yes</span>
                </span>
                <a></a>
              </label>
              <p>Click the switch to the right</p>
            </div>
            {props.getDown3 && (
              <div className="step">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <p>Payment Date</p>
                      <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="MM/DD/YYYY"
                        placeholder={`${formatDate(new Date(), "MM/DD/YYYY")}`}
                        onDayChange={day => props.setPayment3Date(day)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-9">
                    <div className="form-group">
                      <label>Billing name</label>
                      <input
                        ref={props.payment3Name}
                        type="text"
                        className="form-control"
                        id="firstname_booking3"
                        name="firstname_booking3"
                        required={props.getDown3 ? true : false}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Amount</label>
                      <input
                        type="text"
                        className="form-control"
                        id="downpayment_booking3"
                        name="downpayment_booking3"
                        value={props.downpayment3}
                        onChange={e => props.setDownpayment3(e.target.value)}
                        required={props.getDown3 ? true : false}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Card Type</label>
                      <select
                        ref={props.paymentMethod3Ref}
                        className="form-control"
                        name="cardType3"
                        id="cardType3"
                      >
                        <option value="" selected>
                          Select Card Type
                        </option>
                        <option value="Visa">Visa</option>
                        <option value="Master Card">Master Card</option>
                        <option value="Amex">Amex</option>
                        <option value="Discover">Discover</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-9">
                    <div className="form-group">
                      <label>Card #</label>
                      <input
                        ref={props.payment3CardRef}
                        type="text"
                        id="cardnumber3"
                        name="cardnumber3"
                        className="form-control"
                        required={props.getDown3 ? true : false}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Code</label>
                      <input
                        ref={props.payment3CodeRef}
                        type="text"
                        id="cvv3"
                        name="cvv3"
                        className="form-control"
                        required={props.getDown3 ? true : false}
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Country</label>
                      <select
                        ref={props.payment3countryRef}
                        className="form-control"
                        name="country3"
                        id="country3"
                      >
                        <option value="" selected>
                          Select your country
                        </option>
                        <option value="Europe">Europe</option>
                        <option value="United states">United states</option>
                        <option value="Asia">Asia</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Street line 1</label>
                      <input
                        ref={props.payment3addressRef}
                        type="text"
                        id="street_13"
                        name="street_13"
                        className="form-control"
                        required={props.getDown3 ? true : false}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Street line 2</label>
                      <input
                        ref={props.payment3address2Ref}
                        type="text"
                        id="street_23"
                        name="street_23"
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <label>City</label>
                      <input
                        ref={props.payment3cityRef}
                        type="text"
                        id="city_booking3"
                        name="city_booking3"
                        className="form-control"
                        required={props.getDown3 ? true : false}
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="form-group">
                      <label>State</label>
                      <input
                        ref={props.payment3stateRef}
                        type="text"
                        id="state_booking3"
                        name="state_booking3"
                        className="form-control"
                        required={props.getDown3 ? true : false}
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="form-group">
                      <label>Postal code</label>
                      <input
                        ref={props.payment3zipRef}
                        type="text"
                        id="postal_code3"
                        name="postal_code3"
                        className="form-control"
                        required={props.getDown3 ? true : false}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <aside className="col-lg-4">
            <div className="box_style_1">
              <h3 className="inner">- Client Payment -</h3>
              <table className="table table_summary">
                <tbody>
                  <tr>
                    <td>Registration</td>
                    <td className="text-right">
                      <Input
                        value={props.price}
                        onChange={e => props.setPrice(e.target.value)}
                        type="text"
                      ></Input>
                    </td>
                  </tr>
                  <tr>
                    <td>Downpayment</td>
                    <td className="text-right">
                      {props.downpayment
                        ? parseInt(props.downpayment) +
                          parseInt(props.downpayment2) +
                          parseInt(props.downpayment3)
                        : 0}
                    </td>
                  </tr>
                  <tr className="total">
                    <td>Amount Due</td>
                    <td className="text-right">
                      {props.amountDue ? props.amountDue : 0}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="form-group">
                <label for="exampleFormControlTextarea1">
                  Additional Provision
                </label>
                <textarea
                  ref={props.additionalRef}
                  className="form-control"
                  id="additional"
                  rows="3"
                ></textarea>
              </div>
              <button className="btn_full" value="submit">
                Save Payments
              </button>
            </div>
          </aside>
        </div>
      </div>
    </form>
  );
};

export default TbdNewAddPaymentForm;
