import React, { useState, useCallback, useContext, useEffect } from "react";
import { useEventListener } from "../custom/FadeoutNavbar";
import { TbdContext } from "../data/TbdContext";
import auth from "../services/authService";
import { logout } from "../services/authService";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

const TbdHeaderDEMO = () => {
  const [cookies, setCookie] = useCookies(["regSearch"]);
  const { isLoggedIn, tbdDispatch } = useContext(TbdContext);
  const [name, setName] = useState("");
  const [headerClass, setHeaderClass] = useState("gran-header");
  const stickyHandler = useCallback(e => {
    if (e.type === "scroll" && e.srcElement.documentElement.scrollTop > 1)
      setHeaderClass("sticky");
    else setHeaderClass("gran-header");
  }, []);

  useEventListener("scroll", stickyHandler, window);

  useEffect(() => {
    if (auth.getCurrentUser()) {
      const { name } = auth.getCurrentUser();
      setName(name);
      tbdDispatch({ type: "setIsLoggedIn" });
    } else {
      tbdDispatch({ type: "setIsLoggedOut" });
    }
    // eslint-disable-next-line
  }, []);

  function handleLogout() {
    logout();
    tbdDispatch({ type: "setIsLoggedOut" });
  }

  function regLink(country) {
    setCookie("country", country, {
      path: "/registry"
    });
    setCookie("region", "ALL", {
      path: "/registry"
    });
  }

  return (
    <React.Fragment>
      <div className="layer"></div>

      <header className={headerClass}>
        <div id="top_line">
          <div className="container">
            <div className="row">
              <div className="col-5">
                <i className="icon-phone"></i>
                <strong>(773) 985-3001</strong>
              </div>
              <div className="col-7">
                {isLoggedIn && (
                  <ul id="top_links">
                    <li>
                      <Link to="/">Hello {name}</Link>
                    </li>
                    <li>
                      <Link to="/" id="wishlist_link">
                        Wishlist
                      </Link>
                    </li>
                    <li>
                      <a onClick={() => handleLogout()} id="access_link">
                        Sign Out
                      </a>
                    </li>
                  </ul>
                )}
                {!isLoggedIn && (
                  <ul id="top_links">
                    <li>
                      <a
                        onClick={() => tbdDispatch({ type: "toggleSignIn" })}
                        id="access_link"
                      >
                        Sign in
                      </a>
                    </li>
                    <li>
                      <Link href="wishlist.html" id="wishlist_link">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-3">
              <div id="logo_home">
                <h1>
                  <Link href="index.html" title="Travel By Design">
                    Travel By Design
                  </Link>
                </h1>
              </div>
            </div>
            <nav className="col-9">
              <a
                className="cmn-toggle-switch cmn-toggle-switch__htx open_close"
                href="javascript:void(0);"
              >
                <span>Menu mobile</span>
              </a>
              <div className="main-menu">
                <div id="header_menu">
                  <img
                    src="img/logo_sticky.png"
                    width="160"
                    height="34"
                    alt="City tours"
                    data-retina="true"
                  ></img>
                </div>
                <a href="#" className="open_close" id="close_in">
                  <i className="icon_set_1_icon-77"></i>
                </a>

                {auth.getCurrentUser() && (
                  <ul>
                    <li className="submenu">
                      <Link to="/" className="show-submenu">
                        Home <i className="icon-down-open-mini"></i>
                      </Link>
                    </li>
                    <li className="submenu">
                      <Link to="/signature" className="show-submenu">
                        Signature Collection{" "}
                        <i className="icon-down-open-mini"></i>
                      </Link>
                    </li>
                    <li className="megamenu submenu">
                      <Link to="/registry" className="show-submenu-mega">
                        Registry <i className="icon-down-open-mini"></i>
                      </Link>
                      <div className="menu-wrapper">
                        <div className="row">
                          <div className="col-lg-4">
                            <a href="/registry" onClick={() => regLink("ALL")}>
                              View All
                            </a>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <h3>Asia</h3>
                            <ul>
                              <li>
                                <a href="/registry?country=Indonesia">
                                  Indonesia
                                </a>
                              </li>
                              <li>
                                <a href="/registry?country=Malaysia">
                                  Malaysia
                                </a>
                              </li>
                              <li>
                                <a href="/registry?country=Thailand">
                                  Thailand
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="col-lg-4">
                            <h3>Europe</h3>
                            <ul>
                              <li>
                                <a href="/registry?country=Italy">Italy</a>
                              </li>
                              <li>
                                <a href="/registry?country=France">France</a>
                              </li>
                              <li>
                                <a href="/registry?country=Greece">Greece</a>
                              </li>
                            </ul>
                          </div>
                          <div className="col-lg-4">
                            <h3>America</h3>
                            <ul>
                              <li>
                                <a href="/registry?country=United States&region=Hawaii">
                                  Hawaii
                                </a>
                              </li>
                              <li>
                                <a href="/registry?country=United States&region=New York">
                                  New York
                                </a>
                              </li>
                              <li>
                                <a href="/registry?country=United States&region=Florida">
                                  Florida
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <h3>Central</h3>
                            <ul>
                              <li>
                                <a href="/registry?country=Costa Rica">
                                  Costa Rica
                                </a>
                              </li>
                              <li>
                                <a href="/registry?country=Panama">Panama</a>
                              </li>
                              <li>
                                <a href="/registry?country=Colombia">
                                  Colombia
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="col-lg-4">
                            <h3>Australia</h3>
                            <ul>
                              <li>
                                <a href="/registry?country=Australia&region=Queensland">
                                  Queensland
                                </a>
                              </li>
                              <li>
                                <a href="/registry?country=Australia&region=Victoria">
                                  Victoria
                                </a>
                              </li>
                              <li>
                                <a href="/registry?country=Australia&region=Western Australia">
                                  Western
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="col-lg-4">
                            <h3>Caribbean</h3>
                            <ul>
                              <li>
                                <a href="/registry?country=Jamaica">Jamaica</a>
                              </li>
                              <li>
                                <a href="/registry?country=Saint Lucia">
                                  Saint Lucia
                                </a>
                              </li>
                              <li>
                                <a href="/registry?country=Aruba">Aruba</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="submenu">
                      <Link to="/cruises" className="show-submenu">
                        Cruises <i className="icon-down-open-mini"></i>
                      </Link>
                      <ul>
                        <li>
                          <Link to="/cruises">Featured</Link>
                        </li>
                      </ul>
                    </li>
                    <li className="megamenu submenu">
                      <Link to="/design" className="show-submenu-mega">
                        Design Center<i className="icon-down-open-mini"></i>
                      </Link>
                    </li>
                  </ul>
                )}
              </div>
              <ul id="top_tools">
                <li>
                  <a
                    onClick={() => tbdDispatch({ type: "toggleSearch" })}
                    className="search-overlay-menu-btn"
                  >
                    <i className="icon_search"></i>
                  </a>
                </li>
                <li>
                  <div className="dropdown dropdown-cart">
                    <a href="#" data-toggle="dropdown" className="cart_bt">
                      <i className="icon_bag_alt"></i>
                      <strong>3</strong>
                    </a>
                    <ul className="dropdown-menu" id="cart_items">
                      <li>
                        <div className="image">
                          <img src="img/thumb_cart_1.jpg" alt="image"></img>
                        </div>
                        <strong>
                          <a href="#">Louvre museum</a>1x $36.00{" "}
                        </strong>
                        <a href="#" className="action">
                          <i className="icon-trash"></i>
                        </a>
                      </li>
                      <li>
                        <div className="image">
                          <img src="img/thumb_cart_2.jpg" alt="image"></img>
                        </div>
                        <strong>
                          <a href="#">Versailles tour</a>2x $36.00{" "}
                        </strong>
                        <a href="#" className="action">
                          <i className="icon-trash"></i>
                        </a>
                      </li>
                      <li>
                        <div className="image">
                          <img src="img/thumb_cart_3.jpg" alt="image"></img>
                        </div>
                        <strong>
                          <a href="#">Versailles tour</a>1x $36.00{" "}
                        </strong>
                        <a href="#" className="action">
                          <i className="icon-trash"></i>
                        </a>
                      </li>
                      <li>
                        <div>
                          Total: <span>$120.00</span>
                        </div>
                        <a href="cart.html" className="button_drop">
                          Go to cart
                        </a>
                        <a href="payment.html" className="button_drop outline">
                          Check out
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default TbdHeaderDEMO;
