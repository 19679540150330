import React, { createContext } from "react";

export const TbdContext = createContext(null);

export const SignatureContext = createContext(null);

export const useSearcher = () => {
  return { resorts: [] };
};

useSearcher.types = {
  setPage: "SET_PAGE",
  setMonth: "SET_MONTH",
  setUnitSize: "SET_UNITSIZE",
  setCountry: "SET_COUNTRY",
  setVipBox: "SET_VIPBOX",
  modifyAreaSearch: "MODIFY_AREA",
  setDestinations: "SET_DESTINATIONS",
  setResorts: "SET_RESORTS",
  setCount: "SET_COUNT",
  setPageCount: "SET_PAGECOUNT",
  setRegion: "SET_REGION",
  setDistrict: "SET_DISTRICT",
  modifyDistricts: "MOD_DISTRICTS",
  setShowToDistricts: "SET_SHOW_DIST",
  setShowToResorts: "SET_SHOW_RESORTS",
  removeArea: "REMOVE_AREA",
  addArea: "ADD_AREA"
};

export const reducer = (state, action) => {
  switch (action.type) {
    case useSearcher.types.setPage:
      return {
        ...state,
        page: action.payload
      };
    case useSearcher.types.setMonth:
      return {
        ...state,
        month: action.payload
      };
    case useSearcher.types.setUnitSize:
      return {
        ...state,
        unitSize: action.payload
      };
    case useSearcher.types.setCountry:
      return {
        ...state,
        country: action.payload,
        areaToRequest: action.payload === "ALL" ? "country" : "region",
        region: "ALL",
        district: "ALL"
      };
    case useSearcher.types.setRegion:
      return {
        ...state,
        region: action.payload,
        areaToRequest: action.payload === "ALL" ? "region" : "district",
        district: "ALL"
      };
    case useSearcher.types.setVipBox:
      return {
        ...state,
        vipBoxValue: action.payload
      };
    case useSearcher.types.setDestinationsNO:
      if (state.country === "ALL") {
        return {
          ...state,
          list: "area",
          destinations: action.payload.map(destination => {
            return (
              <option key={destination} value={destination}>
                {destination}
              </option>
            );
          })
        };
      } else if (state.region === "ALL") {
        return {
          ...state,
          list: "region",
          district: "ALL",
          regions: action.payload.map(region => {
            return (
              <option key={region} value={region}>
                {region}
              </option>
            );
          })
        };
      } else {
        return {
          ...state,
          list: "district",
          areas: action.payload,
          districts: action.payload
        };
      }
    case useSearcher.types.setResorts:
      if (action.payload.length > 0) {
        return {
          ...state,
          resorts: action.payload
        };
      } else return state;
    case useSearcher.types.setCount:
      return {
        ...state,
        count: action.payload,
        pageCount: Math.ceil(action.payload / 25)
      };
    case useSearcher.types.setPageCount:
      return {
        ...state,
        pageCount: Math.ceil(state.count / 25)
      };
    case "NEXT_PAGE":
      return {
        ...state,
        page:
          state.page < Math.ceil(state.count / 25) ? state.page + 1 : state.page
      };
    case "PREV_PAGE":
      return {
        ...state,
        page: state.page > 1 ? state.page - 1 : state.page
      };
    case useSearcher.types.modifyDistricts:
      state.showableResorts.filter(
        resort => resort.district !== action.payload
      );
      if (!action.payload.value)
        return {
          ...state,
          showableResorts: state.showableResorts.filter(
            resort => resort.district !== action.payload.item
          )
        };

      return {
        ...state,
        showableResorts: [
          ...state.showableResorts,
          ...state.resorts.filter(
            resort => resort.district === action.payload.item
          )
        ]
      };
    case useSearcher.types.setShowToDistricts:
      return {
        ...state,
        showableResorts: state.districts
      };
    case useSearcher.types.setShowToResorts:
      return {
        ...state,
        showableResorts: state.resorts
      };
    case "ADD_AREA":
      const added = [
        ...new Set([
          ...state.resorts.filter(resort => resort._id === action.payload),
          ...state.showableResorts
        ])
      ];
      console.log("updated", added);
      return {
        ...state,
        showableResorts: added
      };
    case "REMOVE_AREA":
      const removed = state.showableResorts.filter(
        resort => resort._id !== action.payload
      );
      console.log("updated", removed);
      return {
        ...state,
        showableResorts: removed
      };
    case "SET_SORTBY":
      return {
        ...state,
        sortBy: action.payload,
        sortByDirection: 1,
        page: 1
      };
    case "SET_SORTBY_DIR":
      return {
        ...state,
        sortByDirection: action.payload,
        page: 1
      };
    case "RELOAD_DEST":
      return {
        ...state,
        destinations: action.payload.destinations.map(destination => {
          return (
            <option key={destination} value={destination}>
              {destination}
            </option>
          );
        }),

        region: action.payload.region,
        areaToRequest: "district"
      };
    case "RELOAD_REGION":
      return {
        ...state,
        regions: action.payload.regions.map(destination => {
          return (
            <option key={destination} value={destination}>
              {destination}
            </option>
          );
        }),
        region: action.payload.region,
        areaToRequest: "district"
      };
    case "RELOAD_OPTIONS":
      return {
        ...state,
        destinations: action.payload.country.map(country => {
          return (
            <option key={country} value={country}>
              {country}
            </option>
          );
        }),
        regions: action.payload.region.map(region => {
          return (
            <option key={region} value={region}>
              {region}
            </option>
          );
        }),
        districts: action.payload.district
      };
    case "SET_COUNTRIES":
      return {
        ...state,
        destinations: action.payload.map(country => {
          return (
            <option key={country} value={country}>
              {country}
            </option>
          );
        }),
        districts: []
      };
    case "SET_REGIONS":
      return {
        ...state,
        regions: action.payload.map(region => {
          return (
            <option key={region} value={region}>
              {region}
            </option>
          );
        }),
        district: "ALL",
        region: "ALL",
        districts: []
      };
    case "SET_DISTRICTS":
      return {
        ...state,
        districts: action.payload
      };
    default:
      return state;
  }
};
