import React, { useState } from "react";

const NewClientProgessBanner = props => {
  return (
    <section id="hero_2">
      <div className="intro_title">
        <h1>Create New Client</h1>
        <div className="bs-wizard row">
          <div
            className={`col-4 bs-wizard-step ${
              props.stage === "New Client" ? "active" : "complete"
            }`}
          >
            <div className="text-center bs-wizard-stepnum">New Client</div>
            <div className="progress">
              <div className="progress-bar"></div>
            </div>
            <a href="cart_hotel.html" className="bs-wizard-dot"></a>
          </div>

          <div
            className={`col-4 bs-wizard-step ${
              props.stage === "Add Payment"
                ? "active"
                : props.stage === "New Client"
                ? "disabled"
                : "complete"
            }`}
          >
            <div className="text-center bs-wizard-stepnum">Add Payment</div>
            <div className="progress">
              <div className="progress-bar"></div>
            </div>
            <a href="#" className="bs-wizard-dot"></a>
          </div>

          <div
            className={`col-4 bs-wizard-step ${
              props.stage === "View Client" ? "active" : "disabled"
            }`}
          >
            <div className="text-center bs-wizard-stepnum">View Client</div>
            <div className="progress">
              <div className="progress-bar"></div>
            </div>
            <a href="confirmation_hotel.html" className="bs-wizard-dot"></a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewClientProgessBanner;
