import React, { useState, useCallback, useRef } from "react";
import { useEventListener } from "../custom/FadeoutNavbar";

const TbdToTop = () => {
  const pxShow = 800; // height on which the button will show
  const [toTopClass, setToTopClass] = useState("");
  const toTopRef = useRef("");
  const toTopHandler = useCallback(e => {
    if (e.type === "scroll" && e.srcElement.documentElement.scrollTop >= pxShow)
      setToTopClass("visible");
    else setToTopClass("");
  }, []);

  const handleClick = useCallback(e => {
    if (e.type === "click")
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEventListener("scroll", toTopHandler, window);
  useEventListener("click", handleClick, toTopRef.current);
  return <div ref={toTopRef} className={toTopClass} id="toTop"></div>;
};

export default TbdToTop;
