import React, { useContext, useCallback } from "react";
import { SignatureContext } from "../data/TbdContext";

const TbdPagination = () => {
  const { page, count, pageCount, signatureDispatch } = useContext(
    SignatureContext
  );

  const handleClick = useCallback(e => {
    const { value } = e.target;
    if (e.type === "click") {
      signatureDispatch({ type: "SET_PAGE", payload: value });
      //window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }
    //
  }, []);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(count / 25); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination justify-content-center">
        <li className="page-item">
          <button
            className="page-link"
            onClick={() => signatureDispatch({ type: "PREV_PAGE" })}
            aria-label="Previous"
          >
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </button>
        </li>
        {pageNumbers.map(number => {
          return (
            <li
              key={number}
              className={`page-item ${number === page ? "active" : ""}`}
            >
              {number === page && (
                <span className="page-link">
                  {number}
                  <span className="sr-only">(current)</span>
                </span>
              )}
              {number !== page && (
                <button
                  className="page-link"
                  value={number}
                  onClick={handleClick}
                >
                  {`${number}`}
                </button>
              )}
            </li>
          );
        })}

        <li className="page-item">
          <button
            className="page-link"
            onClick={() => signatureDispatch({ type: "NEXT_PAGE" })}
            aria-label="Next"
          >
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default TbdPagination;
