import React, { useContext } from "react";
import TbdIncrementInput from "../../tbdFormComponents/TbdIncrementInput/TbdIncrementInput";
import moment from "moment";
import { Input } from "reactstrap";
import { SignatureContext } from "../../data/TbdContext";
import TbdCheckBox from "../../tbdFormComponents/TbdCheckBox/TbdCheckBox";
import DistrictCheckBox from "../../tbdViews/signature/DistrictCheckBox";

const CurrentMonthOptions = () => {
  let monthArray = [];

  for (let count = 0; count < 11; count++) {
    monthArray.push(
      <option
        key={count}
        value={parseInt(
          moment()
            .add(count, "months")
            .format("M")
        )}
      >
        {moment()
          .add(count, "months")
          .format("MMMM")}
      </option>
    );
  }
  return monthArray;
};

const TbdSignatureSidebarSearch = props => {
  const {
    month,
    country,
    useSearcher,
    destinations,
    regions,
    region,
    districts,
    signatureDispatch
  } = useContext(SignatureContext);

  return (
    <aside class="col-lg-3">
      {/** 
      <p>
        <a
          class="btn_map"
          data-toggle="collapse"
          href="#collapseMap"
          aria-expanded="false"
          aria-controls="collapseMap"
          data-text-swap="Hide map"
          data-text-original="View on map"
        >
          View on map
        </a>
      </p>
*/}
      <div id="filters_col">
        <a
          data-toggle="collapse"
          href="#collapseFilters"
          aria-expanded="false"
          aria-controls="collapseFilters"
          id="filters_col_bt"
        >
          <i class="icon_set_1_icon-65"></i>Filters
        </a>
        <div class="collapse show" id="collapseFilters">
          <div class="filter_type">
            <h6>Date</h6>
            <div class="form-group">
              <label>Preferred travel month</label>
              <Input
                type="select"
                onChange={e =>
                  signatureDispatch({
                    type: useSearcher.types.setMonth,
                    payload: e.target.value
                  })
                }
                value={month}
                placeholder={"SEARCH BY MONTH"}
              >
                {CurrentMonthOptions()}
                <option key={"ALL"} value={"ALL"}>
                  ALL
                </option>
              </Input>
            </div>
          </div>

          <div class="filter_type">
            <h6>Location</h6>
            <div class="form-group">
              <label>Preferred travel area</label>
              <Input
                type="select"
                onChange={e =>
                  signatureDispatch({
                    type: useSearcher.types.setCountry,
                    payload: e.target.value
                  })
                }
                value={country}
                placeholder={"FILTER BY LOCATION"}
              >
                {destinations}
                <option value={"ALL"}>ALL</option>
              </Input>
            </div>
          </div>

          <div class="filter_type">
            <h6>Region</h6>
            <ul>
              {country !== "ALL" && (
                <li>
                  <div class="form-group">
                    <label>Preferred travel area</label>
                    <Input
                      type="select"
                      onChange={e =>
                        signatureDispatch({
                          type: useSearcher.types.setRegion,
                          payload: e.target.value
                        })
                      }
                      value={region}
                      placeholder={"FILTER BY REGION"}
                    >
                      {regions}
                      <option value={"ALL"}>ALL</option>
                    </Input>
                  </div>
                </li>
              )}
            </ul>
          </div>

          <div class="filter_type">
            <h6>Distric</h6>
            <ul>
              {region !== "ALL" && (
                <React.Fragment>
                  {districts.map(district => {
                    return (
                      <li key={district}>
                        <DistrictCheckBox
                          name={district}
                          handleDistrictChange={props.handleDistrictChange}
                        />
                      </li>
                    );
                  })}
                </React.Fragment>
              )}
            </ul>
          </div>

          <div class="filter_type">
            <h6>Rooms</h6>
            <TbdIncrementInput
              label={"# or larger"}
              options={[
                { H: -1 },
                { S: 0 },
                { "1": 1 },
                { "2": 2 },
                { "3": 3 },
                { "4": 4 }
              ]}
              pressedColor={"#1d8488"}
              onValueChange={value =>
                signatureDispatch({
                  type: useSearcher.types.setUnitSize,
                  payload: value
                })
              }
            />
          </div>

          <div class="filter_type">
            <h6>Other</h6>
            <ul>
              <li>
                <TbdCheckBox
                  label={"Include VIP Nights"}
                  onValueChange={e =>
                    signatureDispatch({
                      type: useSearcher.types.setVipBox,
                      payload: e.value
                    })
                  }
                  disabled={false}
                  item={"vipBoxValue"}
                  pressedColor={"#1d8488"}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="box_style_2">
        <i class="icon_set_1_icon-57"></i>
        <h4>
          Need <span>Help?</span>
        </h4>
        <a href="tel://17739853001" class="phone">
          (773) 985-3001
        </a>
        <small>Monday to Friday 9.00am - 7.30pm</small>
      </div>
    </aside>
  );
};

export default TbdSignatureSidebarSearch;
