import React from "react";
import TbdSignatureResort from "./TbdSignatureResort";

const TbdSignatureCountryHeader = props => {
  const areaResorts = props.resorts.map(resort => {
    //console.log(resort);
    return (
      <TbdSignatureResort
        dispatch={props.dispatch}
        key={resort.resortId}
        promoRibbon={"green"}
        promoRibbonText={"Hot Deal"}
        resort={resort}
        areaToRequest={props.areaToRequest}
      />
    );
  });

  return (
    <div>
      <div class="banner wow fadeIn" data-wow-delay="0.1s">
        <h4>
          <span style={{ color: "#1d8487" }}>{props.country}</span>{" "}
        </h4>
        <p>
          <strong>Signature </strong>
          <span style={{ color: "#e04f66" }}>
            {props.count === 1 ? "Location" : "Locations"}
          </span>
        </p>
        {props.sortBy !== "price" && (
          <a
            href={
              props.searchedRegion !== "ALL"
                ? `/registry?country=${props.searchedCountry}&region=${props.searchedRegion}`
                : props.searchedCountry !== "ALL"
                ? `/registry?country=${props.searchedCountry}&region=${props.country}`
                : `/registry?country=${props.country}`
            }
            class="btn_1 white"
          >
            {props.country} in Registry
          </a>
        )}
      </div>
      <div>{areaResorts}</div>
    </div>
  );
};

export default TbdSignatureCountryHeader;
