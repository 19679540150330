import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const convertImgUrl = imgurl => {
  return imgurl.replace(
    "https://www.rci.com/static/Resorts/Assets",
    "/signature/resorts"
  );
};

const TbdSignatureResort = props => {
  const { resort } = props;

  const { country, region, district } = resort;
  return (
    <div className="strip_all_tour_list wow fadeIn" data-wow-delay="0.1s">
      <div className="row">
        <div className="col-lg-4 col-md-4">
          {props.promoRibbon && (
            <div
              className={`ribbon_3 ${
                props.promoRibbon === "red" ? "popular" : ""
              }`}
            >
              <span>{props.promoRibbonText}</span>
            </div>
          )}

          <div className="wishlist">
            <Link
              className="tooltip_flip tooltip-effect-1"
              href="javascript:void(0);"
            >
              +
              <span className="tooltip-content-flip">
                <span className="tooltip-back">Add to wishlist</span>
              </span>
            </Link>
          </div>
          <div className="img_list">
            <Link to={`/signature/resort/${resort.resortId}`}>
              <img
                src={convertImgUrl(resort.img)}
                alt={resort.resortName}
              ></img>
              <div className="short_info"></div>
            </Link>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="tour_list_desc ">
            {resort.resortName.length > 60 && (
              <h3>
                <strong>{resort.resortName.slice(0, 60)}...</strong> Resort
              </h3>
            )}
            {resort.resortName.length < 60 && (
              <h3>
                <strong>{resort.resortName}</strong> Resort
              </h3>
            )}
            <a
              onClick={() => {
                props.dispatch({
                  type: "SET_COUNTRY",
                  payload: resort.country
                });
                props.dispatch({
                  type: "SET_REGION",
                  payload: resort.region
                });
              }}
            >{`${region} `}</a>
            ,
            <a
              onClick={() =>
                props.dispatch({
                  type: "SET_COUNTRY",
                  payload: resort.country
                })
              }
            >
              {" "}
              {`${country}`}
            </a>
            {district && (
              <strong>
                <p>{district}</p>
              </strong>
            )}
            <ul className="add_info mb-3">
              <li>
                <a
                  href="javascript:void(0);"
                  className="tooltip-1"
                  data-placement="top"
                  title="Free Wifi"
                >
                  <i className="icon_set_1_icon-86"></i>
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  className="tooltip-1"
                  data-placement="top"
                  title="Plasma TV with cable channels"
                >
                  <i className="icon_set_2_icon-116"></i>
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  className="tooltip-1"
                  data-placement="top"
                  title="Swimming pool"
                >
                  <i className="icon_set_2_icon-110"></i>
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  className="tooltip-1"
                  data-placement="top"
                  title="Fitness Center"
                >
                  <i className="icon_set_2_icon-117"></i>
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  className="tooltip-1"
                  data-placement="top"
                  title="Restaurant"
                >
                  <i className="icon_set_1_icon-58"></i>
                </a>
              </li>
            </ul>
            <h6>
              <span>Availability During:</span>
            </h6>
            <p>
              <i className="icon-calendar-empty"></i>
              <span>
                {moment(resort.startDate).format("MMM Do YYYY")}
              </span> - <i className="icon-calendar-empty"></i>
              <span>{moment(resort.endDate).format("MMM Do YYYY")}</span>
            </p>
          </div>
        </div>
        <div className="col-lg-2 col-md-2">
          <div className="price_list">
            <div>
              <sup>$</sup>
              {Number(resort.price).toFixed(0)}*
              <span className="normal_price_list">
                ${Number(resort.price / 0.75).toFixed(0)}
              </span>
              <small>*From/Per week</small>
              <p>
                <Link
                  to={`/signature/resort/${resort.resortId}`}
                  className="btn_1"
                >
                  Details
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TbdSignatureResort;
