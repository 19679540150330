import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const convertImgUrl = imgurl => {
  return imgurl.replace(
    "https://www.rci.com/static/Resorts/Assets",
    "/signature/resorts"
  );
};

const TbdRegistryResort = props => {
  const [recColOne, setRecColOne] = useState(null);
  const [recColTwo, setRecColTwo] = useState(null);
  useEffect(() => {
    const colOne = props.resorts.slice(0, Math.floor(props.resorts.length / 2));
    const colTwo = props.resorts.slice(
      Math.floor(props.resorts.length / 2),
      props.resorts.length
    );
    setRecColOne(colOne);
    setRecColTwo(colTwo);
    // eslint-disable-next-line
  }, [props.resorts]);

  return (
    <div className="row">
      <div className="col-md-6 wow zoomIn" data-wow-delay="0.1s">
        {recColOne &&
          recColOne.map(resort => {
            return (
              <div key={resort._id} className="hotel_container">
                <div className="img_container">
                  <Link to={`/registry/resort/${resort._id}`}>
                    <div>
                      {resort.img && (
                        <img
                          src={convertImgUrl(resort.img)}
                          width="800"
                          height="533"
                          className="img-fluid"
                          alt={resort.resortName}
                        ></img>
                      )}
                    </div>

                    <div className="score">
                      <span>699</span>
                      {resort.district}
                    </div>

                    <div className="short_info hotel">
                      {resort.region}
                      <span className="price">
                        <sup></sup>
                        {resort.country}
                      </span>
                    </div>
                  </Link>
                </div>

                <div className="hotel_title">
                  <h3>
                    <strong>
                      {resort.resortName.substring(0, 20)}
                      {resort.resortName.length > 20 ? "..." : ""}
                    </strong>
                  </h3>
                  <h3>Resort </h3>

                  <div className="rating"></div>

                  <div className="wishlist">
                    <Link className="tooltip_flip tooltip-effect-1" href="#">
                      +
                      <span className="tooltip-content-flip">
                        <span className="tooltip-back">Add to wishlist</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      <div className="col-md-6 wow zoomIn" data-wow-delay="0.1s">
        {recColTwo &&
          recColTwo.map(resort => {
            return (
              <div key={resort._id} className="hotel_container">
                <div className="img_container">
                  <Link to={`/registry/resort/${resort._id}`}>
                    <div>
                      <img
                        src={resort.img}
                        width="800"
                        height="533"
                        className="img-fluid"
                        alt={resort.resortName}
                      ></img>
                    </div>

                    <div className="score">
                      <span>699</span>
                      {resort.district}
                    </div>

                    <div className="short_info hotel">
                      {resort.region}
                      <span className="price">
                        <sup></sup>
                        {resort.country}
                      </span>
                    </div>
                  </Link>
                </div>

                <div className="hotel_title">
                  <h3>
                    <strong>
                      {resort.resortName.substring(0, 20)}
                      {resort.resortName.length > 20 ? "..." : ""}
                    </strong>
                  </h3>
                  <h3>Resort </h3>

                  <div className="rating"></div>

                  <div className="wishlist">
                    <a className="tooltip_flip tooltip-effect-1" href="#">
                      +
                      <span className="tooltip-content-flip">
                        <span className="tooltip-back">{resort.country}</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TbdRegistryResort;
