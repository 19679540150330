import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { confirm } from "../services/authService";
//import Joi from "joi-browser";
//import LogInHome from "../views/LogInHome";
import { toast } from "react-toastify";
import ConfimHome from "../oldView/ConfirmHome";

const Confirmation = props => {
  console.log(props.match.params.token);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const token = props.match.params.token;
  const errors = "";
  const [passwordAgain, setPasswordAgain] = useState("");
  const [match, setMatch] = useState(true);

  function handleChange(field, value) {
    if (field === "password") {
      setPassword(value);
      setMatch(true);
    } else if (field === "passwordAgain") {
      setPasswordAgain(value);
      setMatch(true);
    } else {
      setEmail(value);
    }
  }
  function handleSubmit(e) {
    e.preventDefault();
    if (passwordAgain !== password) {
      setMatch(false);
      return;
    }

    doSubmit();
  }

  async function doSubmit() {
    try {
      const { data: response } = await confirm({ email, password, token });
      toast.success(`${response}`, {
        position: toast.POSITION.TOP_RIGHT
      });

      props.history.replace("/");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(`Error: ${ex.response.data}`, {
          position: toast.POSITION.TOP_CENTER
        });
        setPassword("");
      }
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col tag="main" className="main-content p-0" lg="12" md="12" sm="12">
          <ConfimHome
            email={email}
            password={password}
            passwordAgain={passwordAgain}
            errors={errors}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            match={match}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Confirmation;
