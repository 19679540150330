import React from "react";
import LazyHero from "react-lazy-hero";
import head4 from "../../assets/head4.jpg";

const TbdDesignCenterBanner = () => {
  return (
    <LazyHero imageSrc={head4} isFixed minHeight={"600px"} opacity={0}>
      <div className="parallax-content-2">
        <div className="container">
          <div className="row">
            <div className="col" style={{ textAlign: "right" }}>
              <h1 className="animated fadeInRight">
                Design <span style={{ fontSize: "large" }}>Center</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </LazyHero>
  );
};

export default TbdDesignCenterBanner;
