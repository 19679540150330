import React, { useContext, useState, useEffect } from "react";
import { TbdContext } from "../../data/TbdContext";
import auth from "../../services/authService";
import lapTopImg from "../../assets/img/laptop.png";
import TbdBanner from "../../tbdPageComponents/tbdSignaturePage/TbdBanner";
import LazyHero from "react-lazy-hero";
import Head5 from "../../assets/head5.jpg";
import {
  TbdBannerMarks,
  TbdBannerContents
} from "../../tbdPageComponents/tbdSignaturePage/TbdBannerContents";

const TbdHome = () => {
  const [activeItem, setActiveItem] = useState(0);
  const { isLoggedIn, tbdDispatch } = useContext(TbdContext);
  const [name, setName] = useState("");
  useEffect(() => {
    if (auth.getCurrentUser()) {
      const { name } = auth.getCurrentUser();
      setName(name);
    } else {
      setName("");
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveItem(activeItem => (activeItem === 3 ? 0 : activeItem + 1));
    }, 5000);
    return () => clearInterval(intervalId);
  });

  return (
    <React.Fragment>
      <LazyHero imageSrc={Head5} isFixed minHeight={"700px"} opacity={0}>
        <div className="container">
          <div
            className="row"
            style={{
              marginLeft: 0 + "px",
              marginRight: 0 + "px",
              height: 90 + "px",
              width: 900 + "px"
            }}
          >
            <div className="col-12" style={{ textAlign: "left" }}>
              <h1 className="animated fadeInRight" style={{ color: "#FFFFFF" }}>
                Travel <span style={{ fontSize: "large" }}>By Design</span>
              </h1>
              <p style={{ fontSize: "large", color: "#FFFFFF" }}>
                Full service agency catering exclusively to private clients
              </p>
            </div>
          </div>
        </div>
      </LazyHero>

      <main>
        <div className="container margin_60">
          {!isLoggedIn && (
            <React.Fragment>
              <div className="main_title">
                <h2>
                  Some <span>good</span> reasons
                </h2>
                <p>
                  Learn more about giving your family the gift of worry free
                  travel.
                </p>
              </div>

              <div className="row">
                <div className="col-lg-4 wow zoomIn" data-wow-delay="0.2s">
                  <div className="feature_home">
                    <i className="icon_set_1_icon-41"></i>
                    <h3>
                      <span>+7100</span> Signature Locations
                    </h3>
                    <p>
                      Enjoy access to discounted, 4 & 5 star accommodations all
                      over the world.
                    </p>
                    <a href="/" className="btn_1 outline">
                      Read more
                    </a>
                  </div>
                </div>

                <div className="col-lg-4 wow zoomIn" data-wow-delay="0.4s">
                  <div className="feature_home">
                    <i className="icon_set_1_icon-30"></i>
                    <h3>
                      <span>+9000</span> Private Clients
                    </h3>
                    <p>
                      Join others like you and become a private client of the
                      most exclusive travel agency.
                    </p>
                    <a href="/" className="btn_1 outline">
                      Read more
                    </a>
                  </div>
                </div>

                <div className="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
                  <div className="feature_home">
                    <i className="icon_set_1_icon-57"></i>
                    <h3>
                      <span>H24 </span> Support
                    </h3>
                    <p>
                      Private Client access is available 24 hours a day, 7 days
                      a week.
                    </p>
                    <a href="/" className="btn_1 outline">
                      Read more
                    </a>
                  </div>
                </div>
              </div>

              <hr></hr>
            </React.Fragment>
          )}

          <div className="row">
            <div className="col-md-6">
              <img
                src={lapTopImg}
                alt="Laptop"
                className="img-fluid laptop"
              ></img>
            </div>
            <div className="col-md-6">
              <h3>
                <span>Get started</span> with TravelByDesign
              </h3>
              <p>As a private client you can...</p>
              <ul className="list_order">
                <li>
                  <span>1</span>Call the Private Concierge Hotline
                </li>
                <li>
                  <span>2</span>Book Online
                </li>
                <li>
                  <span>3</span>Request through Email
                </li>
              </ul>
              <a href="/" className="btn_1">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default TbdHome;
