import React from "react";
import PasswordResetForm from "../oldComponent/PasswordResetForm";
import { Container, Row, Col } from "reactstrap";

const PasswordResetHome = props => {
  return (
    <Container fluid>
      <Row>
        <Col tag="main" className="main-content p-0" lg="12" md="12" sm="12">
          <header className="login">
            <div className="overlay login"></div>

            <div className="container h-100 ">
              <div className="d-flex h-100 text-center align-items-center pt-5">
                <div className="w-100 text-white">
                  <PasswordResetForm {...props} />
                </div>
              </div>
            </div>
          </header>
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordResetHome;
