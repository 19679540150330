import React from "react";
import moment from "moment";

const TbdNewClientSaved = props => {
  return (
    <div className="container margin_60">
      <div className="row">
        <div className="col-lg-8 add_bottom_15">
          <div className="form_title">
            <h3>
              <strong>
                <i className="icon-ok"></i>
              </strong>
              Client Saved
            </h3>
            <p>Print the agreements</p>
          </div>
          <div className="step">
            <p>Client Agreement & Client Understanding</p>
            <a
              className="btn_full"
              href={`/pdf/ca/${props.savedClient._id}`}
              target="_blank"
            >
              Create Client Agreement
            </a>
            <a
              className="btn_full"
              href={`/pdf/cu/${props.savedClient._id}`}
              target="_blank"
            >
              Create Client Understanding
            </a>
          </div>

          <div className="form_title">
            <h3>
              <strong>
                <i className="icon-tag-1"></i>
              </strong>
              Client summary
            </h3>
            <p>Save Client Data</p>
          </div>
          <div className="step">
            <table className="table table-striped confirm">
              <tbody>
                <tr>
                  <td>
                    <strong>DOS</strong>
                  </td>
                  <td>{moment(props.savedClient.dos).format("MM/DD/YYYY")}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Agreement Number:</strong>
                  </td>
                  <td>{props.savedClient._id}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Primary</strong>
                  </td>
                  <td>
                    {props.savedClient.primaryFirst}{" "}
                    {props.savedClient.primaryLast}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Secondary</strong>
                  </td>
                  <td>
                    {props.savedClient.secondaryFirst}{" "}
                    {props.savedClient.secondaryLast}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Purchased</strong>
                  </td>
                  <td>{props.savedClient.product}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Signature Weeks</strong>
                  </td>
                  <td>{props.savedClient.annualWeeks}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Price</strong>
                  </td>
                  <td>{props.savedClient.price}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Down</strong>
                  </td>
                  <td>{props.savedClient.downpayment}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <aside className="col-lg-4">
          <div className="box_style_1">
            <h3 className="inner">Payments</h3>
            <p>Print CC Auth Forms</p>
            <hr></hr>
            <a
              className="btn_full"
              href={`/pdf/cc/payment/0/client/${props.savedClient._id}`}
              target="_blank"
            >
              ${props.savedClient.payments[0].amount}
            </a>
            <a
              className="btn_full"
              href={`/pdf/cc/payment/1/client/${props.savedClient._id}`}
              target="_blank"
            >
              ${props.savedClient.payments[1].amount}
            </a>
            <a
              className="btn_full"
              href={`/pdf/cc/payment/2/client/${props.savedClient._id}`}
              target="_blank"
            >
              ${props.savedClient.payments[2].amount}
            </a>
          </div>
          <div className="box_style_4">
            <i className="icon_set_1_icon-89"></i>
            <h4>
              Have <span>questions?</span>
            </h4>
            <a href="tel://004542344599" className="phone">
              +45 423 445 99
            </a>
            <small>Monday to Friday 9.00am - 7.30pm</small>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default TbdNewClientSaved;
