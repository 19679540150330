import React from "react";
import { Input } from "reactstrap";

const TbdRegistrySortToolsHeader = props => {
  return (
    <div id="tools">
      <div className="row">
        <div className="col-md-3 col-sm-4 col-6">
          <div className="styled-select-filters">
            <Input
              type="select"
              onChange={e => props.setSortBy(e.target.value)}
              value={props.sortBy}
            >
              <option value={"location"}>Sort by location</option>
              <option value={"resort"}>Sort by resort</option>
            </Input>
          </div>
        </div>
        <div className="col-md-3 col-sm-4 col-6">
          <div className="styled-select-filters">
            <Input
              type="select"
              onChange={e => props.setSortByDirection(e.target.value)}
              value={props.sortByDirection}
            >
              <option value={1}>Ascending</option>
              <option value={-1}>Descending</option>
            </Input>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TbdRegistrySortToolsHeader;
