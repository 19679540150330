import React from "react";
import { Input } from "reactstrap";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { formatDate, parseDate } from "react-day-picker/moment";

const TbdAddClientForm = props => {
  return (
    <form onSubmit={props.handleSubmit}>
      <div className="container margin_60">
        <div className="row">
          <div className="col-lg-8 add_bottom_15">
            <div className="form_title">
              <h3>
                <strong>1</strong>Client Profile
              </h3>
              <p>Enter to create new client</p>
            </div>
            <div className="step">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <p>Date</p>
                    <DayPickerInput
                      formatDate={formatDate}
                      parseDate={parseDate}
                      format="MM/DD/YYYY"
                      placeholder={`${formatDate(new Date(), "MM/DD/YYYY")}`}
                      onDayChange={day => props.setDos(day)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>First name</label>
                    <input
                      ref={props.primaryFirstRef}
                      type="text"
                      className="form-control"
                      id="firstname_booking"
                      name="firstname_booking"
                    ></input>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Last name</label>
                    <input
                      ref={props.primaryLastRef}
                      type="text"
                      className="form-control"
                      id="lastname_booking"
                      name="lastname_booking"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      ref={props.emailRef}
                      type="email"
                      id="email_booking"
                      name="email_booking"
                      className="form-control"
                    ></input>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Telephone</label>
                    <input
                      ref={props.homePhoneRef}
                      type="text"
                      id="telephone_booking"
                      name="telephone_booking"
                      className="form-control"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Cell Phone</label>
                    <input
                      ref={props.cellPhoneRef}
                      type="text"
                      id="telephone_booking"
                      name="telephone_booking"
                      className="form-control"
                    ></input>
                  </div>
                </div>
              </div>
            </div>

            <div className="form_title">
              <h3>
                <strong>2</strong>Add Spouse?
              </h3>
              <label className="switch-light switch-ios float-right">
                <input
                  type="checkbox"
                  name="option_5"
                  id="option_5"
                  checked={props.getSpouse}
                  onClick={() => props.setGetSpouse(getSpouse => !getSpouse)}
                ></input>
                <span>
                  <span>No</span>
                  <span>Yes</span>
                </span>
                <a></a>
              </label>
              <p>Click the switch to the right</p>
            </div>
            {props.getSpouse && (
              <div className="step">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>First name</label>
                      <input
                        ref={props.secondaryFirstRef}
                        type="text"
                        className="form-control"
                        id="secondaryFirst"
                        name="secondaryFirst"
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Last name</label>
                      <input
                        ref={props.secondaryLastRef}
                        type="text"
                        className="form-control"
                        id="secondaryLas"
                        name="secondaryLas"
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        ref={props.email2Ref}
                        type="email"
                        id="email2"
                        name="email2"
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Telephone</label>
                      <input
                        ref={props.workPhoneRef}
                        type="text"
                        id="workphone"
                        name="workphone"
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="form_title">
              <h3>
                <strong>3</strong>Address
              </h3>
              <p>Main address to keep in client profile</p>
            </div>
            <div className="step">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Country</label>
                    <select
                      ref={props.countryRef}
                      className="form-control"
                      name="country"
                      id="country"
                    >
                      <option value="" selected>
                        Select your country
                      </option>
                      <option value="Europe">Europe</option>
                      <option value="United states">United states</option>
                      <option value="Asia">Asia</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Street line 1</label>
                    <input
                      ref={props.addressRef}
                      type="text"
                      id="street_1"
                      name="street_1"
                      className="form-control"
                    ></input>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Street line 2</label>
                    <input
                      ref={props.address2Ref}
                      type="text"
                      id="street_2"
                      name="street_2"
                      className="form-control"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label>City</label>
                    <input
                      ref={props.cityRef}
                      type="text"
                      id="city_booking"
                      name="city_booking"
                      className="form-control"
                    ></input>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="form-group">
                    <label>State</label>
                    <input
                      ref={props.stateRef}
                      type="text"
                      id="state_booking"
                      name="state_booking"
                      className="form-control"
                    ></input>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="form-group">
                    <label>Postal code</label>
                    <input
                      ref={props.zipRef}
                      type="text"
                      id="postal_code"
                      name="postal_code"
                      className="form-control"
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <aside className="col-lg-4">
            <div className="box_style_1">
              <h3 className="inner">- Client Package -</h3>
              <table className="table table_summary">
                <tbody>
                  <tr>
                    <td>Rep</td>
                    <td className="text-right">
                      <Input innerRef={props.repRef} type="select">
                        <option value="5e140095ec9f189faeceb1f1">REP</option>
                      </Input>
                    </td>
                  </tr>
                  <tr>
                    <td>Leader</td>
                    <td className="text-right">
                      <Input innerRef={props.leaderRef} type="select">
                        <option value="5e140095ec9f189faeceb1f1">Leader</option>
                      </Input>
                    </td>
                  </tr>
                  <tr>
                    <td>Package</td>
                    <td className="text-right">
                      <Input innerRef={props.productRef} type="select">
                        <option value="Gold">Gold</option>
                        <option value="Silver">Silver</option>
                        <option value="Bronze">Bronze</option>
                      </Input>
                    </td>
                  </tr>
                  <tr>
                    <td>Term</td>
                    <td className="text-right">
                      <Input innerRef={props.termRef} type="select">
                        <option value={80}>80</option>
                      </Input>
                    </td>
                  </tr>
                  <tr>
                    <td>Annual Weeks</td>
                    <td className="text-right">
                      <Input
                        innerRef={props.annualWeeksRef}
                        type="text"
                      ></Input>
                    </td>
                  </tr>
                  <tr>
                    <td>Registration</td>
                    <td className="text-right">
                      <Input
                        value={props.price}
                        onChange={props.handlePriceChange}
                        type="text"
                      ></Input>
                    </td>
                  </tr>
                  <tr>
                    <td>Down Payment</td>
                    <td className="text-right">
                      <Input
                        value={props.downpayment}
                        onChange={props.handleDownpaymentChange}
                        type="text"
                      ></Input>
                    </td>
                  </tr>
                  <tr className="total">
                    <td>Amount Due</td>
                    <td className="text-right">
                      {props.amountDue ? props.amountDue : 0}
                    </td>
                  </tr>

                  <tr>
                    <td>Cert #</td>
                    <td className="text-right">
                      <Input innerRef={props.promoRef} type="text"></Input>
                    </td>
                  </tr>
                </tbody>
              </table>
              <button className="btn_full" value="submit">
                Save New Client
              </button>
            </div>
          </aside>
        </div>
      </div>
    </form>
  );
};

export default TbdAddClientForm;
