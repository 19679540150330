import React from "react";
import TbdCheckBox from "../../tbdFormComponents/TbdCheckBox/TbdCheckBox";

const DistrictCheckBox = props => {
  //const checkBoxRef = useRef(null);

  return (
    <TbdCheckBox
      //ref={checkBoxRef}
      label={props.name}
      onValueChange={e => {
        //console.log(e);
        props.handleDistrictChange(e);
      }}
      defaultChecked={true}
      disabled={false}
      pressedColor={"#1d8488"}
      item={props.name}
    />
  );
};

export default DistrictCheckBox;
