import React, { useEffect, useState } from "react";
import {
  getRegCount,
  getRegOptions,
  getChunk,
  getChunkRegion
} from "../../services/signatureService";
import TbdBreadcrumbHeader from "../../tbdCommon/TbdBreadcrumbHeader";
import TbdRegistrySortToolsHeader from "../../tbdPageComponents/TbdRegistryPage/TbdRegistrySortToolsHeader";
import TbdSimplePagination from "../../tbdCommon/TbdSimplePagination";
import TbdRegistryResort from "../../tbdPageComponents/TbdRegistryResort";
import TbdRegistrySidebarSearch from "../../tbdPageComponents/TbdRegistryPage/TbdRegisrtySidebarSearch";
import { useCookies } from "react-cookie";
import TbdRegistryBanner from "../../tbdPageComponents/TbdRegistryPage/TbdRegistryBanner";
import queryString from "query-string";

const TbdRegistry = props => {
  const [sortBy, setSortBy] = useState("location");
  const [sortByDirection, setSortByDirection] = useState(1);
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState(null);
  const [country, setCountry] = useState("ALL");
  const [region, setRegion] = useState("ALL");
  const [district, setDistrict] = useState("ALL");
  const [count, setCount] = useState(1);
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [store, setStore] = useState([]);
  const [cookies, setCookie] = useCookies(["regSearch"]);
  const [firstLoad, setFirstLoad] = useState(true);
  const parsed = queryString.parse(props.location.search);

  //REGISTRY
  useEffect(() => {
    //Check for cookies
    if (firstLoad && parsed.country) {
      setCountry(parsed.country);
      let searchRegion = "ALL";
      if (parsed.region) searchRegion = parsed.region;
      getChunk({
        country: parsed.country,
        region: searchRegion,
        district,
        sortBy,
        sortByDirection,
        page
      })
        .then(newPageData => {
          setPageData(newPageData);
          return newPageData;
        })
        .then(result => {
          getRegCount({ country, region, district }).then(setCount);
          return count;
        })
        .then(count => {
          getRegOptions({ country, region, district }).then(opts => {
            if (country === "ALL") {
              setCountries(opts.values);
              setDistricts([]);
            } else if (region === "ALL") {
              setRegions(opts.values);
              setDistricts([]);
            } else {
              setDistricts(opts.values);
            }
          });
          return searchRegion;
        })
        .then(searchRegion => {
          setRegion(searchRegion);
        });

      setFirstLoad(false);
    } else if (firstLoad && cookies.country) {
      console.log("First Load");
      setCountry(cookies.country);

      getChunk({
        country: cookies.country,
        region: cookies.region,
        district,
        sortBy,
        sortByDirection,
        page
      })
        .then(newPageData => {
          setPageData(newPageData);
          return newPageData;
        })
        .then(result => {
          getRegCount({ country, region, district }).then(setCount);
          return count;
        })
        .then(count => {
          getRegOptions({ country, region, district }).then(opts => {
            if (country === "ALL") {
              setCountries(opts.values);
              setDistricts([]);
            } else if (region === "ALL") {
              setRegions(opts.values);
              setDistricts([]);
            } else {
              setDistricts(opts.values);
            }
          });
          return region;
        })
        .then(reg => {
          setRegion(cookies.region);
        });
      setFirstLoad(false);
    } else {
      if (region !== "ALL") {
        getChunkRegion({
          country,
          region,
          district,
          sortBy,
          sortByDirection
        })
          .then(result => {
            setPageData(result);
            return result;
          })
          .then(allResorts => {
            setStore(allResorts);
            getRegOptions({ country, region, district }).then(opts => {
              //setValues(opts.values);
              setDistricts(opts.values);
            });
          });
      } else {
        getChunk({
          country,
          region,
          district,
          sortBy,
          sortByDirection,
          page
        })
          .then(newPageData => {
            setPageData(newPageData);
            return newPageData;
          })
          .then(result => {
            getRegCount({ country, region, district }).then(setCount);
            return count;
          })
          .then(count => {
            getRegOptions({ country, region, district }).then(opts => {
              if (country === "ALL") {
                setCountries(opts.values);
                setDistricts([]);
              } else if (region === "ALL") {
                setRegions(opts.values);
                setDistricts([]);
              } else {
                setDistricts(opts.values);
              }
            });
          });
      }
    }

    // eslint-disable-next-line
  }, [page, country, region, district, sortBy, sortByDirection]);

  useEffect(() => {
    setRegion("ALL");
    setDistrict("ALL");
  }, [country]);

  useEffect(() => {
    setDistrict("ALL");
    setPage(1);
  }, [country, district, region]);

  function handleDistrictChange(e) {
    if (e.value === false) {
      const newPageData = pageData.filter(resort => {
        return resort.district !== e.item;
      });
      setPageData(newPageData);
    } else {
      //Clean data first
      const newPageData = pageData.filter(resort => {
        return resort.district !== e.item;
      });
      //Add from Store
      const addedData = store.filter(resort => {
        return resort.district === e.item;
      });

      setPageData([...addedData, ...newPageData]);
      setCount(pageData.length);
    }
  }

  return (
    <React.Fragment>
      <TbdRegistryBanner />

      <main>
        <TbdBreadcrumbHeader />
        <div className="collapse" id="collapseMap">
          <div id="map" className="map"></div>
        </div>

        <div className="container margin_60">
          <div className="row">
            <TbdRegistrySidebarSearch
              setCountry={setCountry}
              setRegion={setRegion}
              setDistric={setDistrict}
              country={country}
              region={region}
              district={district}
              countries={countries}
              regions={regions}
              districts={districts}
              handleDistrictChange={handleDistrictChange}
              setCookie={setCookie}
            />

            <div className="col-lg-9">
              {region === "ALL" && (
                <TbdRegistrySortToolsHeader
                  countries={countries}
                  regions={regions}
                  districts={districts}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  sortByDirection={sortByDirection}
                  setSortByDirection={setSortByDirection}
                />
              )}

              {pageData && (
                <React.Fragment>
                  <TbdSimplePagination
                    page={page}
                    setPage={setPage}
                    count={count}
                  />

                  {/**
              <div class="main_title">
                <h2 className>
                  {props.reaId} <span>ALL</span> Registry
                </h2>
                <p></p>
                <h4>
                  {count} - <span>Resorts</span>
                </h4>
                <p></p>
                <Link href={`/signature/${props._id}`} class="btn_1 white">
                  Search Signature Collection
                </Link>
              </div>
 */}
                  <TbdRegistryResort
                    resorts={pageData}
                    country={country}
                    region={region}
                  />

                  <TbdSimplePagination
                    page={page}
                    setPage={setPage}
                    count={count}
                  />
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default TbdRegistry;
