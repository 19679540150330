import React, { useEffect, useRef, useState } from "react";
import hotelsPin from "../assets/img/pins/Hotels.png";

const TopGoogleMap = (props) => {
  const googleMapScript = document.createElement("script");
  const [googleMap, setGoogleMap] = useState(null);
  const [gmapObject, setGmapObject] = useState(null);
  const googleMapRef = useRef(null);

  useEffect(() => {
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${props.apikey}&libraries=places`;
    window.document.body.appendChild(googleMapScript);

    googleMapScript.addEventListener("load", () => {
      setGoogleMap(() => createGoogleMap());
      createMarker();
    });
    // eslint-disable-next-line
  }, []);

  const createGoogleMap = () => {
    const mapObject = new window.google.maps.Map(googleMapRef.current, {
      zoom: 14,
      center: new window.google.maps.LatLng(props.lat, props.lng),
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        position: window.google.maps.ControlPosition.LEFT_CENTER,
      },
      panControl: false,
      panControlOptions: {
        position: window.google.maps.ControlPosition.TOP_RIGHT,
      },
      zoomControl: true,
      zoomControlOptions: {
        style: window.google.maps.ZoomControlStyle.LARGE,
        position: window.google.maps.ControlPosition.TOP_LEFT,
      },
      scrollwheel: false,
      scaleControl: false,
      scaleControlOptions: {
        position: window.google.maps.ControlPosition.TOP_LEFT,
      },
      streetViewControl: false,
      streetViewControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_TOP,
      },
      styles: [
        {
          featureType: "administrative.country",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "administrative.province",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "administrative.locality",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "administrative.neighborhood",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          elementType: "all",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "landscape.natural.landcover",
          elementType: "all",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "landscape.natural.terrain",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.attraction",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.business",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.government",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.medical",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "all",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.place_of_worship",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.school",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.sports_complex",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.highway.controlled_access",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "all",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "all",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit.station.airport",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit.station.bus",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit.station.rail",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
    });
    //console.log(props.lat, props.lng);
    const marker = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(props.lat, props.lng),
      map: mapObject,
      icon: hotelsPin,
    });
    window.google.maps.event.addListener(marker, "click", function () {
      const item = {
        name: "Hotel Mariott",
        location_latitude: props.lat,
        location_longitude: props.lng,
        map_image_url: "img/thumb_map_1.jpg",
        name_point: "Hotel Mariott",
        description_point:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        get_directions_start_address: "",
        phone: "+3934245255",
        url_point: "single_hotel.html",
      };

      //getInfoBox(item).open(mapObject, marker);
      mapObject.setCenter(new window.google.maps.LatLng(props.lat, props.lng));
    });
    //function getInfoBox(item) {
    //  console.log(item);
    // }

    setGmapObject(mapObject);
    return mapObject;
  };

  const createMarker = () => {
    new window.google.maps.Marker({
      position: new window.google.maps.LatLng(props.lat, props.lng),
      map: gmapObject,
      icon: hotelsPin,
    });
  };

  return (
    <div
      id="google-map"
      ref={googleMapRef}
      style={{ width: 100 + "%", height: 500 + "px" }}
    />
  );
};

export default TopGoogleMap;
