import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../services/authService";
import { ModalContext } from "../data/ModalContext";
import { TbdContext } from "../data/TbdContext";

const ProtectedRoute = ({ route, index }) => {
  const { client, dispatch } = useContext(ModalContext);
  const { isLoggedIn } = useContext(TbdContext);
  return (
    <Route
      key={index}
      path={route.path}
      exact={route.exact}
      render={props => {
        if (!auth.getCurrentUser()) return <Redirect to="/" />;

        if (route.admin) {
          if (!auth.userIsAdmin()) return <Redirect to="/" />;
          return (
            <route.layout {...props}>
              <route.component {...props} />
            </route.layout>
          );
        }

        if (!client && isLoggedIn) {
          const { email, name, _id: client } = auth.getCurrentUser();
          dispatch({ type: "load-client", payload: { email, name, client } });
        }

        return (
          <route.layout {...props}>
            <route.component {...props} />
          </route.layout>
        );
      }}
    />
  );
};

export default ProtectedRoute;
