import React, { useRef, useState, useEffect } from "react";
import NewClientProgessBanner from "../../tbdPageComponents/TbdNewClient/NewClientProgressBanner";
import TbdBreadcrumbHeader from "../../tbdCommon/TbdBreadcrumbHeader";

import moment from "moment";
import {
  createClient,
  saveClientPayments
} from "../../services/newClientService";
import TbdAddClientForm from "./TbdAddClientForm";
import TbdNewClientSaved from "./TbdNewClientSaved";
import TbdNewAddPaymentForm from "./TbdNewAddPaymentForm";

const TbdAddClientContract = () => {
  const [dos, setDos] = useState(moment().format("MM/DD/YYYY"));
  const primaryFirstRef = useRef("");
  const primaryLastRef = useRef("");
  const emailRef = useRef("");
  const homePhoneRef = useRef("");
  const cellPhoneRef = useRef("");
  const [getSpouse, setGetSpouse] = useState(false);
  const secondaryFirstRef = useRef("");
  const secondaryLastRef = useRef("");
  const workPhoneRef = useRef("");
  const email2Ref = useRef("");
  const countryRef = useRef("");
  const addressRef = useRef("");
  const address2Ref = useRef("");
  const cityRef = useRef("");
  const stateRef = useRef("");
  const zipRef = useRef("");
  const repRef = useRef("");
  const leaderRef = useRef("");
  const productRef = useRef("");
  const termRef = useRef("");
  const annualWeeksRef = useRef("");
  const [price, setPrice] = useState(0);
  const [downpayment, setDownpayment] = useState(0);
  const [amountDue, setAmountDue] = useState(0);
  const promoRef = useRef("");

  const [payment1Date, setPayment1Date] = useState(
    moment().format("MM/DD/YYYY")
  );
  const payment1Name = useRef("");
  const [downpayment1, setDownpayment1] = useState(0);
  const downpayment1Ref = useRef("");
  const paymentMethodRef = useRef("");
  const payment1CardRef = useRef("");
  const payment1CodeRef = useRef("");
  const payment1countryRef = useRef("");
  const payment1addressRef = useRef("");
  const payment1address2Ref = useRef("");
  const payment1cityRef = useRef("");
  const payment1stateRef = useRef("");
  const payment1zipRef = useRef("");
  const [getDown2, setGetDown2] = useState(false);
  const [payment2Date, setPayment2Date] = useState(
    moment().format("MM/DD/YYYY")
  );
  const payment2Name = useRef("");
  const [downpayment2, setDownpayment2] = useState(0);
  const paymentMethod2Ref = useRef("");
  const payment2CardRef = useRef("");
  const payment2CodeRef = useRef("");
  const payment2countryRef = useRef("");
  const payment2addressRef = useRef("");
  const payment2address2Ref = useRef("");
  const payment2cityRef = useRef("");
  const payment2stateRef = useRef("");
  const payment2zipRef = useRef("");
  const [getDown3, setGetDown3] = useState(false);
  const [payment3Date, setPayment3Date] = useState(
    moment().format("MM/DD/YYYY")
  );
  const payment3Name = useRef("");
  const [downpayment3, setDownpayment3] = useState(0);
  const paymentMethod3Ref = useRef("");
  const payment3CardRef = useRef("");
  const payment3CodeRef = useRef("");
  const payment3countryRef = useRef("");
  const payment3addressRef = useRef("");
  const payment3address2Ref = useRef("");
  const payment3cityRef = useRef("");
  const payment3stateRef = useRef("");
  const payment3zipRef = useRef("");
  const [totalDown, setTotalDown] = useState(0);
  const additionalRef = useRef("N/A");

  const adminFeeRef = useRef("");
  const adminFeeWaivedRef = useRef("");
  const totalRef = useRef("");
  const [savedClient, setSavedClient] = useState(null);
  const [stage, setStage] = useState("New Client");

  function handleSubmit(e) {
    e.preventDefault();

    const client = {
      dos,
      primaryFirst: primaryFirstRef.current.value,
      primaryLast: primaryLastRef.current.value,
      secondaryFirst:
        getSpouse === false ? "N/A" : secondaryFirstRef.current.value,
      secondaryLast: getSpouse === false ? "-" : secondaryLastRef.current.value,
      homePhone: homePhoneRef.current.value,
      cellPhone: cellPhoneRef.current.value,
      workPhone: getSpouse === false ? "" : workPhoneRef.current.value,
      address: addressRef.current.value,
      address2: address2Ref.current.value ? address2Ref.current.value : "",
      city: cityRef.current.value,
      state: stateRef.current.value,
      zip: zipRef.current.value,
      country: countryRef.current.value,
      email: emailRef.current.value,
      email2: getSpouse === false ? "" : email2Ref.current.value,
      term: termRef.current.value,
      annualWeeks: annualWeeksRef.current.value,
      price,
      downpayment: totalDown,
      amountDue,
      rep: repRef.current.value,
      leader: leaderRef.current.value,
      product: productRef.current.value,
      promo: promoRef.current.value
    };

    createClient(client).then(saved => {
      console.log(saved);
      if (saved._id) {
        setSavedClient(saved);
        setStage("Add Payment");
      }
    });
  }

  function handlePriceChange(e) {
    setPrice(e.target.value);
    setAmountDue(e.target.value - downpayment);
  }

  function handleDownpaymentChange(e) {
    setDownpayment(e.target.value);
    setAmountDue(price - e.target.value);
  }

  function handleSavePayments(e) {
    e.preventDefault();
    console.log("submit");
    const clientId = savedClient._id;
    const additional = additionalRef.current.value;
    const payments = [
      {
        date: payment1Date,
        amount: downpayment,
        method: paymentMethodRef.current.value,
        billingName: payment1Name.current.value,
        cardNumber: payment1CardRef.current.value,
        code: payment1CodeRef.current.value,
        address: payment1addressRef.current.value,
        address2: payment1address2Ref.current.value,
        city: payment1cityRef.current.value,
        state: payment1stateRef.current.value,
        zip: payment1zipRef.current.value,
        country: payment1countryRef.current.value
      },
      {
        date: getDown2 ? payment2Date : null,
        amount: getDown2 ? downpayment2 : 0,
        method: getDown2 ? paymentMethod2Ref.current.value : "-",
        billingName: getDown2 ? payment2Name.current.value : "",
        cardNumber: getDown2 ? payment2CardRef.current.value : "",
        code: getDown2 ? payment2CodeRef.current.value : "",
        address: getDown2 ? payment2addressRef.current.value : "",
        address2: getDown2 ? payment2address2Ref.current.value : "",
        city: getDown2 ? payment2cityRef.current.value : "",
        state: getDown2 ? payment2stateRef.current.value : "",
        zip: getDown2 ? payment2zipRef.current.value : 0,
        country: getDown2 ? payment2countryRef.current.value : ""
      },
      {
        date: getDown3 ? payment3Date : null,
        amount: getDown3 ? downpayment3 : 0,
        method: getDown3 ? paymentMethod3Ref.current.value : "-",
        billingName: getDown3 ? payment3Name.current.value : "",
        cardNumber: getDown3 ? payment3CardRef.current.value : "",
        code: getDown3 ? payment3CodeRef.current.value : "",
        address: getDown3 ? payment3addressRef.current.value : "",
        address2: getDown3 ? payment3address2Ref.current.value : "",
        city: getDown3 ? payment3cityRef.current.value : "",
        state: getDown3 ? payment3stateRef.current.value : "",
        zip: getDown3 ? payment3zipRef.current.value : 0,
        country: getDown3 ? payment3countryRef.current.value : ""
      }
    ];
    saveClientPayments({
      clientId,
      payments,
      totalDown,
      price,
      additional
    }).then(updatedClient => {
      setSavedClient(updatedClient);
      setStage("View Client");
    });
  }

  useEffect(() => {
    setAmountDue(
      parseInt(price) -
        (parseInt(downpayment) +
          parseInt(downpayment2) +
          parseInt(downpayment3))
    );
    setTotalDown(
      parseInt(downpayment) + parseInt(downpayment2) + parseInt(downpayment3)
    );
  }, [downpayment, downpayment2, downpayment3, price]);

  return (
    <React.Fragment>
      <NewClientProgessBanner stage={stage} />
      <main>
        <TbdBreadcrumbHeader />
        {!savedClient && (
          <TbdAddClientForm
            handleSubmit={handleSubmit}
            setGetSpouse={setGetSpouse}
            getSpouse={getSpouse}
            setDos={setDos}
            handleDownpaymentChange={handleDownpaymentChange}
            handlePriceChange={handlePriceChange}
            primaryFirstRef={primaryFirstRef}
            primaryLastRef={primaryLastRef}
            secondaryFirstRef={secondaryFirstRef}
            secondaryLastRef={secondaryLastRef}
            homePhoneRef={homePhoneRef}
            cellPhoneRef={cellPhoneRef}
            workPhoneRef={workPhoneRef}
            addressRef={addressRef}
            address2Ref={address2Ref}
            cityRef={cityRef}
            stateRef={stateRef}
            zipRef={zipRef}
            countryRef={countryRef}
            emailRef={emailRef}
            email2Ref={email2Ref}
            termRef={termRef}
            annualWeeksRef={annualWeeksRef}
            repRef={repRef}
            leaderRef={leaderRef}
            productRef={productRef}
            promoRef={promoRef}
            amountDue={amountDue}
          />
        )}
        {savedClient && stage === "Add Payment" && (
          <TbdNewAddPaymentForm
            handleSavePayments={handleSavePayments}
            amountDue={amountDue}
            price={price}
            setPrice={setPrice}
            savedClient={savedClient}
            getDown2={getDown2}
            setGetDown2={setGetDown2}
            getDown3={getDown3}
            setGetDown3={setGetDown3}
            downpayment={downpayment}
            setDownpayment={setDownpayment}
            downpayment1={downpayment}
            setDownpayment1={setDownpayment}
            downpayment1Ref={downpayment1Ref}
            paymentMethodRef={paymentMethodRef}
            payment1Date={payment1Date}
            setPayment1Date={setPayment1Date}
            payment1addressRef={payment1addressRef}
            payment1address2Ref={payment1address2Ref}
            payment1cityRef={payment1cityRef}
            payment1stateRef={payment1stateRef}
            payment1zipRef={payment1zipRef}
            payment1countryRef={payment1countryRef}
            payment1CardRef={payment1CardRef}
            payment1CodeRef={payment1CodeRef}
            payment1Name={payment1Name}
            downpayment2={downpayment2}
            setDownpayment2={setDownpayment2}
            paymentMethod2Ref={paymentMethod2Ref}
            setPayment2Date={setPayment2Date}
            payment2addressRef={payment2addressRef}
            payment2address2Ref={payment2address2Ref}
            payment2cityRef={payment2cityRef}
            payment2stateRef={payment2stateRef}
            payment2zipRef={payment2zipRef}
            payment2countryRef={payment2countryRef}
            payment2CardRef={payment2CardRef}
            payment2CodeRef={payment2CodeRef}
            payment2Name={payment2Name}
            downpayment3={downpayment3}
            setDownpayment3={setDownpayment3}
            paymentMethod3Ref={paymentMethod3Ref}
            setPayment3Date={setPayment3Date}
            payment3addressRef={payment3addressRef}
            payment3address2Ref={payment3address2Ref}
            payment3cityRef={payment3cityRef}
            payment3stateRef={payment3stateRef}
            payment3zipRef={payment3zipRef}
            payment3countryRef={payment3countryRef}
            payment3CardRef={payment3CardRef}
            payment3CodeRef={payment3CodeRef}
            payment3Name={payment3Name}
            additionalRef={additionalRef}
          />
        )}
        {savedClient && stage === "View Client" && (
          <TbdNewClientSaved savedClient={savedClient} />
        )}
      </main>
    </React.Fragment>
  );
};

export default TbdAddClientContract;
