import React, { useCallback } from "react";

const TbdSimplePagination = props => {
  const page = parseInt(props.page);

  const handleClick = useCallback(
    e => {
      const { value } = e.target;
      if (e.type === "click") {
        //signatureDispatch({ type: "SET_PAGE", payload: value });
        props.setPage(value);
        //window.scroll({ top: 0, left: 0, behavior: "smooth" });
      }
      //
    },
    // eslint-disable-next-line
    []
  );

  const pageNumbersArray = [
    props.page < 2 ? 1 : parseInt(props.page) - 1,
    parseInt(props.page) === 1 ? parseInt(props.page) + 1 : props.page,
    parseInt(props.page) === 1
      ? parseInt(props.page) + 2
      : props.page === Math.ceil(parseInt(props.count) / 30)
      ? Math.ceil(parseInt(props.count) / 30)
      : parseInt(props.page) + 1
  ];

  const pageNumbers = pageNumbersArray.splice(
    0,
    parseInt(props.count) < 30
      ? 1
      : parseInt(props.count) < 60
      ? 2
      : pageNumbersArray.length
  );

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination justify-content-center">
        <li className="page-item">
          <button
            className="page-link"
            onClick={() => {
              if (page !== 1) {
                props.setPage(page - 1);
              } else {
                return;
              }
            }}
            aria-label="Previous"
          >
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </button>
        </li>
        {pageNumbers.map(number => {
          return (
            <li
              key={number}
              className={`page-item ${number === props.page ? "active" : ""}`}
            >
              {number === props.page && (
                <span className="page-link">
                  {number}
                  <span className="sr-only">(current)</span>
                </span>
              )}
              {number !== props.page && (
                <button
                  className="page-link"
                  value={number}
                  onClick={handleClick}
                >
                  {`${number}`}
                </button>
              )}
            </li>
          );
        })}

        <li className="page-item">
          <button
            className="page-link"
            onClick={() => {
              if (page !== Math.ceil(parseInt(props.count) / 30)) {
                props.setPage(page + 1);
              } else {
                return;
              }
            }}
            aria-label="Next"
          >
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default TbdSimplePagination;
