import Holiday from "../assets/dc/holidaylettings.png";
import LastMin from "../assets/dc/lastminute.png";
import ViatorPic from "../assets/dc/viator.png";
import ProEx from "../assets/dc/projecte.png";
import Military from "../assets/dc/military.png";
import Tickets from "../assets/dc/ticketsatwork.png";

export default function getDcPlatforms() {
  return [
    {
      name: "Holiday Lettings",
      description: "Rentals, Apartments & Villas",
      img: Holiday,
      filter: "lodging",
      link: "https://www.holidaylettings.co.uk/"
    },
    {
      name: "Last minute travel",
      description: "unique travel experiences",
      img: LastMin,
      filter: "lodging",
      link: "https://www.lastminutetravel.com/"
    },
    {
      name: "Viator",
      description: "100,000 bookable activities",
      img: ViatorPic,
      filter: "activities",
      link: "https://www.partner.viator.com/en/79251"
    },
    {
      name: "Project Expedition",
      description: "tours, & attractions",
      img: ProEx,
      filter: "activities",
      link: "https://www.projectexpedition.com"
    },
    {
      name: "Military",
      description: "Military Tours",
      img: Military,
      filter: "activities",
      link: "https://www.beyondbandofbrothers.com"
    },
    {
      name: "Tickets At Work",
      description: "All Tickets",
      img: Tickets,
      filter: "activities",
      link: "https://www.ticketsatwork.com/tickets/"
    }
  ];
}
