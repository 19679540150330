import React from "react";
import LazyHero from "react-lazy-hero";
import HeadImg from "../../assets/head2.jpg";
//import SignatureCounter from "./BannerComponents/SignatureCounter";

const TbdParallaxBanner = props => {
  return (
    <LazyHero
      imageSrc={HeadImg}
      isFixed
      minHeight={"600px"}
      color={"#4d536d"}
      opacity={0.2}
    >
      <div className="parallax-content-2">
        <div className="container">
          <div className="row">
            <div className="col" style={{ textAlign: "right" }}>
              <h1 className="animated fadeInLeft">
                Signature <span style={{ fontSize: "large" }}>Collection</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </LazyHero>
  );
};

export default TbdParallaxBanner;
