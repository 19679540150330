import React, { useReducer, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ProtectedRoute from "./custom/ProtectedRoute";
import routes from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";

//TBD.....
import "./assets/css/style.css";
import "./assets/css/vendors2.css";
import "./assets/css/custom.css";
import "animate.css";

//import Wow from "./assets/js/wow";
import { TbdContext } from "./data/TbdContext";
import TbdDefault from "./layouts/TbdDefault";
import TbdHome from "./tbdViews/home/TbdHome";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Confirmation from "./layouts/Confirmation";
import PasswordResetHome from "./oldView/PasswordResetHome";
import { CSSTransition } from "react-transition-group";
import { ModalContext, CruiseContext } from "./data/ModalContext";

//import "react-table/react-table.css";

toast.configure();

const reducer = (state, action) => {
  switch (action.type) {
    case "toggle":
      return { ...state, modal: !state.modal };
    case "load-and-toggle":
      return {
        ...state,
        item: action.payload.item,
        collection:
          action.payload.collection === "Cruises"
            ? "cruises"
            : action.payload.collection === "VIP"
            ? "vip"
            : action.payload.collection === "Compass Weeks"
            ? "compass"
            : action.payload.collection === "Registry Collection"
            ? "registry"
            : "compass",
        modal: !state.modal
      };
    case "load-client":
      return {
        ...state,
        client: action.payload.client,
        email: action.payload.email,
        name: action.payload.email
      };
    default:
      return state;
  }
};

const tbdReducer = (state, action) => {
  switch (action.type) {
    case "toggleSearch":
      return {
        ...state,
        hasFullscreenModal: !state.hasFullscreenModal,
        searchIsOpen: !state.searchIsOpen
      };
    case "loginUser":
      return {
        ...state,
        isLoggedIn: true,
        userName: action.payload.name,
        email: action.payload.email
      };
    case "setIsLoggedIn and toggle":
      return {
        ...state,
        isLoggedIn: true,
        isSignInOpen: !state.isSignInOpen
      };
    case "setIsLoggedIn":
      return {
        ...state,
        isLoggedIn: true
      };
    case "setIsLoggedOut":
      return {
        ...state,
        isLoggedIn: false
      };
    case "toggleSignIn":
      return {
        ...state,
        isSignInOpen: !state.isSignInOpen,
        isForgotPassword: false
      };
    case "setIsForgotPassword":
      return {
        ...state,
        isForgotPassword: true
      };
    case "setLoading":
      return {
        ...state,
        isLoading: action.payload
      };
    default:
      return state;
  }
};
const App = () => {
  const [
    { item, collection, client, email, name, modal },
    dispatch
  ] = useReducer(reducer, {
    item: null,
    collection: null,
    client: null,
    modal: false
  });
  const [
    {
      hasFullscreenModal,
      searchIsOpen,
      isLoggedIn,
      userName,
      userEmail,
      isSignInOpen,
      isForgotPassword,
      isLoading
    },
    tbdDispatch
  ] = useReducer(tbdReducer, {
    hasFullscreenModal: false,
    searchIsOpen: false,
    isLoggedIn: false,
    isSignInOpen: false,
    isForgotPassword: false,
    isLoading: false
  });

  const [stops, setStops] = useState([]);
  const [cModal, setCmodal] = useState(false);

  return (
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
      <TbdContext.Provider
        value={{
          hasFullscreenModal,
          searchIsOpen,
          isLoggedIn,
          isSignInOpen,
          isForgotPassword,
          isLoading,
          tbdDispatch
        }}
      >
        <Route
          path="/"
          exact={true}
          render={props => {
            return (
              <TbdDefault {...props}>
                <TbdHome {...props} />
              </TbdDefault>
            );
          }}
        />
      </TbdContext.Provider>
      <Route path="/login" component={TbdDefault} />
      <Route path="/:id/cx/:token" component={Confirmation} />
      <Route path="/xreset" component={PasswordResetHome} />

      {routes.map((route, index) => {
        return (
          <TbdContext.Provider
            key={"tbd" + index}
            value={{
              hasFullscreenModal,
              searchIsOpen,
              isLoggedIn,
              userName,
              userEmail,
              isSignInOpen,
              isForgotPassword,
              isLoading,
              tbdDispatch
            }}
          >
            <ModalContext.Provider
              key={index + item}
              value={{
                item,
                collection,
                client,
                email,
                name,
                modal,
                dispatch
              }}
            >
              <CruiseContext.Provider
                value={{ stops, setStops, cModal, setCmodal }}
              >
                <ProtectedRoute key={index} route={route} index={index}>
                  {({ match }) => (
                    <CSSTransition
                      in={match != null}
                      timeout={300}
                      classNames="page"
                      unmountOnExit
                    >
                      <div className="page">
                        <Route.Component />
                      </div>
                    </CSSTransition>
                  )}
                </ProtectedRoute>
              </CruiseContext.Provider>
            </ModalContext.Provider>
          </TbdContext.Provider>
        );
      })}
    </Router>
  );
};

export default App;
