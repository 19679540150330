import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import greyImg from "./grey.png";

//props
//disabled: boolean
//lable: String
//onValueChange: callback fn
const TbdCheckBox = props => {
  const savedHandler = useRef(null);
  const checkBoxRef = useRef(null);
  const innerCheckBoxRef = useRef(null);
  const [isChecked, setIsChecked] = useState(
    props.defaultChecked ? true : false
  );
  const [isHover, setIsHover] = useState(false);
  const [backgroundPosition, setBackgroundPosition] = useState();

  const CheckDiv = styled.div`
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px 0 0;
    padding: 0;
    width: 22px;
    height: 22px;
    background: url(${greyImg}) no-repeat;
    border: none;
    cursor: pointer;
    background-position: ${props => props.backgroundPosition};
  `;

  const LabelSpan = styled.span`
    font-weight: ${isHover ? "bolder" : isChecked ? "bold" : "initial"};
    color: ${props => {
      return isHover
        ? props.hoverColor
        : isChecked
        ? props.hoverColor
        : "initial";
    }};
  `;

  const hoverHandler = useCallback(
    e => {
      if (e.type === "mouseover" && true) setIsHover(true);
      else if (e.type === "mouseout" && true) setIsHover(false);
      else setIsHover(false);
    },
    [setIsHover]
  );

  useEffect(() => {
    savedHandler.current = hoverHandler;
  }, [hoverHandler]);

  useEffect(() => {
    const isSupported =
      checkBoxRef.current && checkBoxRef.current.addEventListener;
    if (!isSupported) return;
    const eventListener = event => savedHandler.current(event);
    checkBoxRef.current.addEventListener("mouseover", eventListener);
    return () => {
      checkBoxRef.current.removeEventListener("mouseover", eventListener);
    };
  }, ["mouseover", checkBoxRef.current]);

  useEffect(() => {
    const isSupported =
      checkBoxRef.current && checkBoxRef.current.addEventListener;
    if (!isSupported) return;
    const eventListener = event => savedHandler.current(event);
    checkBoxRef.current.addEventListener("mouseout", eventListener);
    return () => {
      checkBoxRef.current.removeEventListener("mouseout", eventListener);
    };
  }, ["mouseout", checkBoxRef.current]);

  useEffect(() => {
    if (isChecked && props.disabled) {
      setBackgroundPosition(`-96px 0`);
    } else if (isChecked) {
      setBackgroundPosition(`-48px 0`);
    } else if (props.disabled) {
      setBackgroundPosition(`-72px 0`);
    } else if (isHover) {
      setBackgroundPosition(`-24px 0`);
    } else setBackgroundPosition(`initial`);
  }, [isChecked, props.disabled, isHover]);

  useEffect(() => {
    props.onValueChange({ item: props.item, value: isChecked });
  }, [isChecked]);

  return (
    <label ref={checkBoxRef}>
      <CheckDiv backgroundPosition={backgroundPosition}>
        <input
          ref={innerCheckBoxRef}
          type="checkbox"
          style={{ position: "absolute", opacity: 0 }}
          onClick={() => setIsChecked(isChecked => !isChecked)}
          disabled={props.disabled}
          value={isChecked}
        ></input>
      </CheckDiv>
      <LabelSpan hoverColor={props.pressedColor}>{props.label}</LabelSpan>
    </label>
  );
};

export default TbdCheckBox;
