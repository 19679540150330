import React, { useReducer, useEffect, useState } from "react";
import styled from "styled-components";
import plusImg from "./plus.png";
import minusImg from "./minus.png";

const reducer = (state, action) => {
  switch (action.type) {
    case "inc":
      return {
        ...state,
        index:
          state.index < state.options.length - 1
            ? state.index + 1
            : state.index,
        rooms:
          state.options[
            state.index < state.options.length - 1
              ? state.index + 1
              : state.index
          ]
      };
    case "dec":
      return {
        ...state,
        index: state.index > 0 ? state.index - 1 : state.index,
        rooms: state.options[state.index > 0 ? state.index - 1 : state.index]
      };
    default:
      return state;
  }
};
//props
//label: String
//options: Array
//onValueChange: callback fn
//pressedColor: String '#1d8488'

const TbdIncrementInput = props => {
  const [{ rooms, index, options }, dispatch] = useReducer(reducer, {
    rooms: props.options[0],
    index: 0,
    options: props.options
  });
  const [incBackground, setIncBackground] = useState("#fff");
  const [decBackground, setDecBackground] = useState("#fff");

  useEffect(() => {
    const [key] = Object.values(rooms);
    props.onValueChange(key);
  }, [rooms]);

  return (
    <FormGroupDiv>
      <label>{props.label}</label>
      <NumbersRow>
        <QtyInput
          type="text"
          value={Object.keys(rooms)}
          id="rooms"
          name="rooms"
          readOnly={true}
        ></QtyInput>

        <IncButton
          onClick={() => dispatch({ type: "inc" })}
          //onMouseUp={() => dispatch({ type: "mouseUp" })}
          //isPressed={incIsPressed}
          backGround={incBackground}
          onMouseDown={() =>
            setIncBackground(incBackground =>
              index < options.length - 1 ? props.pressedColor : incBackground
            )
          }
          onMouseUp={() => setIncBackground("#fff")}
        >
          +
        </IncButton>
        <DecButton
          onClick={() => dispatch({ type: "dec" })}
          //onMouseUp={() => dispatch({ type: "mouseUp" })}
          // isPressed={decIsPressed}
          backGround={decBackground}
          onMouseDown={() =>
            setDecBackground(decBackground =>
              index > 0 ? props.pressedColor : decBackground
            )
          }
          onMouseUp={() => setDecBackground("#fff")}
        >
          -
        </DecButton>
      </NumbersRow>
    </FormGroupDiv>
  );
};

const FormGroupDiv = styled.div`
  margin-bottom: 1rem;
`;

const NumbersRow = styled.div`
  position: relative;
  width: 97px;
  height: 40px;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
`;

const QtyInput = styled.input`
  position: relative;
  width: 35px;
  height: 40px;
  text-align: center;
  left: 31px;
  font-size: 12px;
  padding: 5px;
  color: #333;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  display: block;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border-top-color: #ced4da;
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: #ced4da;
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: #ced4da;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: #ced4da;
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition-property: border-color, box-shadow;
  transition-duration: 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out;
  transition-delay: 0s, 0s;
`;

const IncButton = styled.div`
  text-indent: -9999px;
  cursor: pointer;
  position: absolute;
  width: 33px;
  height: 40px;
  z-index: 9;
  background-image: url(${plusImg});
  background-position-x: center;
  background-position-y: center;
  background-size: initial;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: ${props => {
    return props.backGround;
  }};
  right: 0;
  top: 0;
  border-top-color: #ccc;
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: #ccc;
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: #ccc;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: #ccc;
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const DecButton = styled.div`
  text-indent: -9999px;
  cursor: pointer;
  position: absolute;
  width: 33px;
  height: 40px;
  z-index: 9;
  background-image: url(${minusImg});
  background-position-x: center;
  background-position-y: center;
  background-size: initial;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: ${props => {
    return props.backGround;
  }};
  border-top-color: #ccc;
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: #ccc;
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: #ccc;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: #ccc;
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  left: 0;
  top: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;
export default TbdIncrementInput;
