import React from "react";
import styled from "styled-components";
//import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

import "../App.css";

const LoaderDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-color: #ffffff59;
  z-index: 999999999;
`;

const WaveSpinner = styled.div`
  background-color: #e04f667d;
  height: 100%;
  width: 400px;
  display: inline-block;
  animation: sk-waveStretchDelay 1.2s infinite ease-out;
  animation-duration: 1.2s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: sk-waveStretchDelay;
  text-align: center;
  font-size: 10px;
  line-height: 20px;
  font-family: "Montserrat", Arial, sans-serif;
  color: #555;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
`;
const loader = (
  <LoaderDiv>
    <div class="sk-cube-grid">
      <div class="sk-cube sk-cube1"></div>
      <div class="sk-cube sk-cube2"></div>
      <div class="sk-cube sk-cube3"></div>
      <div class="sk-cube sk-cube4"></div>
      <div class="sk-cube sk-cube5"></div>
      <div class="sk-cube sk-cube6"></div>
      <div class="sk-cube sk-cube7"></div>
      <div class="sk-cube sk-cube8"></div>
      <div class="sk-cube sk-cube9"></div>
    </div>
  </LoaderDiv>
);

const toggle = ["", loader];
const TbdPreLoader = props => {
  return (
    <div>
      {/** 
      <CSSTransitionGroup
        transitionName="tbd"
        transitionAppear={true}
        transitionAppearTimeout={400}
        transitionEnter={false}
        transitionLeaveTimeout={200}
      >
        {toggle[props.switch]}
      </CSSTransitionGroup>
      */}
    </div>
  );
};

export default TbdPreLoader;
