import React, { useContext, useRef, useState } from "react";
import { Modal, Input, FormFeedback } from "reactstrap";
import { TbdContext } from "../data/TbdContext";
import { login, passwordReset } from "../services/authService";

const TbdSignInModal = () => {
  const { isForgotPassword, isSignInOpen, tbdDispatch } = useContext(
    TbdContext
  );
  const [errors, setErrors] = useState({});
  const [resetPasswordMessage, setResetPasswordMessage] = useState(null);
  //const [gotoContact, setGotoContact] = useState(false);
  //const [isForgotPassword, setIsForgotPassword] = useState(false);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confimEmailRef = useRef(null);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!isForgotPassword) {
      try {
        const { data: jwt } = await login({
          email: emailRef.current.value,
          password: passwordRef.current.value
        });
        localStorage.setItem("token", jwt);

        tbdDispatch({ type: "setIsLoggedIn and toggle" });
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          console.log(ex.response.data);
          setErrors(ex.response.data);
        }
      }
    } else {
      try {
        const { data: response } = await passwordReset(
          confimEmailRef.current.value
        );
        console.log(response);
        setResetPasswordMessage(response);
      } catch (ex) {
        console.log(ex);
        setResetPasswordMessage(
          "There seems to be an issue with your account. Please contact our office."
        );
      }
    }
  }

  function handleCloser() {
    setResetPasswordMessage(null);
    tbdDispatch({ type: "toggleSignIn" });
  }
  return (
    <Modal
      isOpen={isSignInOpen}
      toggle={() => tbdDispatch({ type: "toggleSignIn" })}
      id="sign-in-dialog"
      zIndex={200000}
      contentClassName="noBorder"
    >
      <div className="small-dialog-header">
        <h3>Sign In</h3>
      </div>
      {!resetPasswordMessage && (
        <form onSubmit={handleSubmit}>
          <div className="sign-in-wrapper">
            {!isForgotPassword && (
              <React.Fragment>
                <div className="form-group">
                  <label>Email</label>
                  <Input
                    innerRef={emailRef}
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Email"
                    autoComplete="email"
                  ></Input>
                  <i className="icon_mail_alt"></i>
                  <FormFeedback></FormFeedback>
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    ref={passwordRef}
                    type="password"
                    className="form-control"
                    name="password"
                    id="password"
                  ></input>
                  <i className="icon_lock_alt"></i>
                </div>
                <div className="clearfix add_bottom_15">
                  <div className="checkboxes float-left">
                    <input
                      id="remember-me"
                      type="checkbox"
                      name="check"
                    ></input>
                    <label for="remember-me">Remember Me</label>
                  </div>
                  <div className="float-right">
                    <a
                      id="forgot"
                      onClick={() =>
                        tbdDispatch({ type: "setIsForgotPassword" })
                      }
                    >
                      Forgot Password?
                    </a>
                  </div>
                </div>
                <div className="text-center">
                  <input
                    type="submit"
                    value="Log In"
                    className="btn_login"
                  ></input>
                </div>
              </React.Fragment>
            )}

            {isForgotPassword && (
              <React.Fragment>
                <div className="form-group">
                  <label>Please confirm login email below</label>
                  <Input
                    type="email"
                    className="form-control"
                    name="email_forgot"
                    id="email_forgot"
                    innerRef={confimEmailRef}
                  ></Input>

                  <i className="icon_mail_alt"></i>
                </div>
                <p>
                  You will receive an email containing a link allowing you to
                  reset your password to a new preferred one.
                </p>
                <div className="text-center">
                  <input
                    type="submit"
                    value="Reset Password"
                    className="btn_1 mb-2"
                  ></input>
                </div>
              </React.Fragment>
            )}
            <div className="text-center">
              Don’t have an account? <a href="javascript:void(0);">Sign up</a>
            </div>
          </div>
        </form>
      )}
      {resetPasswordMessage && (
        <div>
          <p>{resetPasswordMessage}</p>
          <div className="text-center">
            <button onClick={() => handleCloser()} className="btn_1 mb-2">
              Close
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default TbdSignInModal;
