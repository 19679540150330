import React, { useState, useEffect } from "react";
import TbdBreadcrumbHeader from "../../tbdCommon/TbdBreadcrumbHeader";
import ResortCarousel from "../../tbdCommon/ResortCarousel";
import { getResortFromId } from "../../services/signatureService";
import LazyHero from "react-lazy-hero";
import HeadImg from "../../assets/head2.jpg";
import classnames from "classnames";
import TbeAvailableResort from "../../tbdPageComponents/tbdResortPage/TbdAvailableResort";
import GoogleMap from "../../custom/GoogleMap";
import WeatherWidget from "../../custom/WeatherWidget";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import TopGoogleMap from "../../custom/TopGoogleMap";

const ResortPage = props => {
  const [cookies, setCookie] = useCookies(["regSearch"]);
  const [activeTab, setActiveTab] = useState("1");
  const [resort, setResort] = useState(null);
  const [recColOne, setRecColOne] = useState(null);
  const [recColTwo, setRecColTwo] = useState(null);
  const [availableUnits, setAvailableUnits] = useState(null);
  const [startingAt, setStartingAt] = useState(null);
  //TOGGLE THIS FOR VIP INVENTORY OVER 399
  const [showVip, setShowVip] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    getResortFromId(props.match.params.id)
      .then(result => {
        setResort(result.resort);
        if (props.match.path === "/signature/resort/:id") {
          setStartingAt(result.startingAt);
        } else {
          setStartingAt(699);
        }

        const { availableUnitsIds: allAvailableUnitsIds } = result.resort;

        //const { location } = result;
        //console.log(result.location.lattDegree, result.location.longDegree);
        const { lattDegree, longDegree } = result.resort.location;
        setLat(lattDegree);
        setLng(longDegree);
        if (props.match.path === "/signature/resort/:id") {
          const availableUnitsIds = allAvailableUnitsIds.filter(unit => {
            if (showVip) {
              return unit.fullPrice > 0;
            } else {
              return unit.fullPrice < 399;
            }
          });
          setAvailableUnits(availableUnitsIds);
          console.log(availableUnitsIds);
        }

        return result.resort.recreationOnsite.filter(rec => rec !== null);
      })
      .then(recs => {
        const colOne = recs.slice(0, Math.floor(recs.length / 2));
        const colTwo = recs.slice(Math.floor(recs.length / 2), recs.length);
        setRecColOne(colOne);
        setRecColTwo(colTwo);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (resort && props.match.path === "/signature/resort/:id") {
      setCookie("country", resort.area.country, {
        path: "/signature"
      });
      setCookie("region", resort.area.region ? resort.area.region : "ALL", {
        path: "/signature"
      });
    } else if (resort && props.match.path === "/registry/resort/:id") {
      setCookie("country", resort.area.country, {
        path: "/registry"
      });
      setCookie("region", resort.area.region ? resort.area.region : "ALL", {
        path: "/registry"
      });
    }
    return;

    // eslint-disable-next-line
  }, [resort]);

  return (
    <main>
      <LazyHero
        isFixed
        imageSrc={HeadImg}
        minHeight={"250px"}
        color={"#4d536d"}
        opacity={"0.6"}
      >
        {resort && (
          <div className="parallax-content-2">
            <div className="container">
              <div className="row">
                <div
                  className="col-md-8 text-align-left"
                  style={{ textAlign: "left" }}
                >
                  <span className="rating"></span>
                  <h1>{resort.resortName}</h1>
                  <span>{resort.area.country}</span>
                </div>
                {startingAt && (
                  <div className="col-md-4">
                    {props.match.path === "/signature/resort/:id" && (
                      <div id="price_single_main" className="hotel">
                        from/per week{" "}
                        <span>
                          <sup>$</sup>
                          {startingAt}
                        </span>
                      </div>
                    )}
                    {props.match.path === "/registry/resort/:id" && (
                      <div id="price_single_main" className="hotel">
                        less than/per week{" "}
                        <span>
                          <sup>$</sup>
                          {startingAt}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </LazyHero>
      <TbdBreadcrumbHeader />

      <div className={`collapse ${showMap ? "show" : ""}`} id="collapseMap">
        {lng && (
          <TopGoogleMap
            lat={lat}
            lng={lng}
            apikey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
          />
        )}
      </div>

      <div className="container margin_60">
        <div className="row">
          <div className="col-lg-8" id="single_tour_desc">
            <div id="single_tour_feat">
              {resort && (
                <ul>
                  <li className="pull-left" style={{ textAlign: "left" }}>
                    {props.match.path === "/signature/resort/:id" && (
                      <a href={`/signature`}>
                        <i className="arrow_left"></i>Back to{" "}
                        {resort.area.country} - {resort.area.region}
                      </a>
                    )}
                    {props.match.path === "/registry/resort/:id" && (
                      <a href={`/registry`}>
                        <i className="arrow_left"></i>Back to{" "}
                        {resort.area.country} - {resort.area.region}
                      </a>
                    )}
                  </li>
                </ul>
              )}

              {/*
              <ul>
                <li>
                  <i className="icon_set_2_icon-116"></i>Plasma TV
                </li>
                <li>
                  <i className="icon_set_1_icon-86"></i>Free Wifi
                </li>
                <li>
                  <i className="icon_set_2_icon-110"></i>Poll
                </li>
                <li>
                  <i className="icon_set_1_icon-59"></i>Breakfast
                </li>
                <li>
                  <i className="icon_set_1_icon-22"></i>Pet allowed
                </li>
                <li>
                  <i className="icon_set_1_icon-13"></i>Accessibiliy
                </li>
                <li>
                  <i className="icon_set_1_icon-27"></i>Parking
                </li>
              </ul>
         */}
            </div>
          </div>
          <aside className="col-lg-4">
            <p className="d-none d-xl-block d-lg-block d-xl-none">
              <a
                className="btn_map"
                data-toggle="collapse"
                href="#collapseMap"
                aria-expanded="false"
                aria-controls="collapseMap"
                data-text-swap="Hide map"
                data-text-original="View on map"
                onClick={() => setShowMap(showMap => !showMap)}
              >
                {showMap ? "Hide Map" : "View on map"}
              </a>
            </p>
          </aside>
        </div>

        <div className="row">
          <div className="col-lg-8" id="single_tour_desc">
            <p className="d-none d-md-block d-block d-lg-none">
              <a
                className="btn_map"
                data-toggle="collapse"
                href="#collapseMap"
                aria-expanded="false"
                aria-controls="collapseMap"
                data-text-swap="Hide map"
                data-text-original="View on map"
              >
                View on map
              </a>
            </p>
            {resort && (
              <ResortCarousel
                regweek={resort}
                className={"slider-pro"}
                convertImgUrl={convertImgUrl}
              />
            )}

            <hr></hr>

            <div className="row">
              <div id="tabs" className="tabs">
                <nav>
                  <ul>
                    <li
                      className={classnames({
                        "tab-current": activeTab === "1"
                      })}
                    >
                      <a
                        onClick={() => {
                          toggle("1");
                        }}
                        className="icon-resort"
                      >
                        <span>Resort</span>
                      </a>
                    </li>
                    <li
                      className={classnames({
                        "tab-current": activeTab === "2"
                      })}
                    >
                      <a
                        onClick={() => {
                          toggle("2");
                        }}
                        className="icon-loc"
                      >
                        <span>Location</span>
                      </a>
                    </li>
                    <li
                      className={classnames({
                        "tab-current": activeTab === "3"
                      })}
                    >
                      <a
                        onClick={() => {
                          toggle("3");
                        }}
                        className="icon-locmap"
                      >
                        <span>Directions</span>
                      </a>
                    </li>
                    {props.match.path === "/signature/resort/:id" && (
                      <li
                        className={classnames({
                          "tab-current": activeTab === "4"
                        })}
                      >
                        <a
                          onClick={() => {
                            toggle("4");
                          }}
                          className="icon-availability"
                        >
                          <span>Availability</span>
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
                <div className="content">
                  <section
                    id="section-1"
                    className={classnames({
                      "content-current": activeTab === "1"
                    })}
                  >
                    {resort && (
                      <div className="row">
                        <div className="col-lg-3">
                          <h3>Description</h3>
                        </div>
                        <div className="col-lg-9">
                          <p>{resort.resortUnitInfo}</p>

                          <div className="row">
                            <div className="col-md-6">
                              {recColOne && (
                                <ul className="list_ok">
                                  {recColOne.map(recreation => {
                                    return (
                                      <li key={recreation}>{recreation}</li>
                                    );
                                  })}
                                </ul>
                              )}
                            </div>
                            <div className="col-md-6">
                              {recColTwo && (
                                <ul className="list_ok">
                                  {recColTwo.map(recreation => {
                                    return (
                                      <li key={recreation}>{recreation}</li>
                                    );
                                  })}
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </section>
                  <section
                    id="section-2"
                    className={classnames({
                      "content-current": activeTab === "2"
                    })}
                  >
                    <div className="row list_tours_tabs">
                      {resort && (
                        <div className="col-lg-9">
                          <h2>Location</h2>
                          <p>{resort.areaInfo}</p>
                        </div>
                      )}
                    </div>
                  </section>
                  <section
                    id="section-3"
                    className={classnames({
                      "content-current": activeTab === "3"
                    })}
                  >
                    <div className="row list_hotels_tabs">
                      {resort && (
                        <div className="col-lg-9">
                          {resort.directions && (
                            <React.Fragment>
                              <h2>Directions</h2>
                              <p>{resort.directions}</p>
                            </React.Fragment>
                          )}
                          {resort.airportInfo && (
                            <React.Fragment>
                              <h2>Airport</h2>
                              <p>{resort.airportInfo}</p>
                            </React.Fragment>
                          )}
                        </div>
                      )}
                    </div>
                  </section>
                  {props.match.path === "/signature/resort/:id" && (
                    <section
                      id="section-4"
                      className={classnames({
                        "content-current": activeTab === "4"
                      })}
                    >
                      {availableUnits && (
                        <div>
                          {availableUnits.map(unit => {
                            return (
                              <TbeAvailableResort
                                key={unit._id}
                                promoRibbonText="test"
                                unit={unit}
                                img={convertImgUrl(resort.img[0])}
                                resortName={resort.resortName}
                                checkIn={resort.checkIn}
                                checkOut={resort.checkOut}
                              />
                            );
                          })}
                        </div>
                      )}
                    </section>
                  )}
                </div>
              </div>
            </div>

            <hr></hr>
          </div>

          <aside className="col-lg-4">
            {resort && <WeatherWidget id={resort._id} area={resort.area} />}
            {/*
            <div className="box_style_1 expose">
              <h3 className="inner">Check Availability</h3>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <i className="icon-calendar-7"></i> Check in
                    </label>
                    <input
                      className="date-pick form-control"
                      data-date-format="M d, D"
                      type="text"
                    ></input>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <i className="icon-calendar-7"></i> Check out
                    </label>
                    <input
                      className="date-pick form-control"
                      data-date-format="M d, D"
                      type="text"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Adults</label>
                    <div className="numbers-row">
                      <input
                        type="text"
                        value="1"
                        id="adults"
                        className="qty2 form-control"
                        name="quantity"
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Children</label>
                    <div className="numbers-row">
                      <input
                        type="text"
                        value="0"
                        id="children"
                        className="qty2 form-control"
                        name="quantity"
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>

              <br></br>
              <a className="btn_full" href="cart_hotel.html">
                Check now
              </a>
              <a className="btn_full_outline" href="#">
                <i className=" icon-heart"></i> Add to whislist
              </a>
            </div>
*/}
            <hr></hr>
            <div className="box_style_4">
              <i className="icon_set_1_icon-90"></i>
              <h4>
                <span>Book</span> by phone
              </h4>
              <a href="tel://17739853001" className="phone">
                (773) 985-3001
              </a>
              <small>Monday to Friday 9.00am - 7.30pm</small>
            </div>
          </aside>
        </div>
      </div>
    </main>
  );
};

function convertImgUrl(imgurl = "") {
  if (imgurl !== "") {
    return imgurl.replace(
      "https://www.rci.com/static/Resorts/Assets",
      "/signature/resorts"
    );
  } else {
    return;
  }
}
export default ResortPage;
