import { db_get, db_post } from "./dbService";
//import { get } from "mongoose";

export function getResortFromId(id) {
  return db_post(`/signature/resort`, { id });
}

export function searchSignatureCollection({
  month,
  unitSize,
  page,
  country,
  region,
  district,
  vipBoxValue,
  sortBy,
  sortByDirection
}) {
  return db_post(`/signature/sigfind`, {
    month,
    unitSize,
    page,
    country,
    region,
    district,
    vipBoxValue,
    sortBy,
    sortByDirection
  });
}

export function getRegOptions({ country, region, district }) {
  return db_post("/signature/regoptions", {
    country,
    region,
    district
  });
}

export function getOptions({ month, unitSize, country, region, vipBoxValue }) {
  return db_post("/signature/options", {
    month,
    unitSize,
    country,
    region,
    vipBoxValue
  });
}

export function getSigOptions({
  month,
  unitSize,
  country,
  region,
  vipBoxValue
}) {
  return db_post("/signature/sigoptions", {
    month,
    unitSize,
    country,
    region,
    vipBoxValue
  });
}

export function getSigCount() {
  return db_get("/signature/sigcount");
}

export function searchRegistryResorts({
  regCountry,
  regRegion,
  regDistrict,
  page
}) {
  return db_post("/signature/searchreg", {
    country: regCountry,
    region: regRegion,
    district: regDistrict,
    page: page
  });
}

export function getRegCount({ country, region, district }) {
  return db_post("/signature/countreg", { country, region, district });
}

export function getChunk({
  country: regCountry,
  region: regRegion,
  district: regDistrict,
  sortBy,
  sortByDirection,
  page
}) {
  return db_post("/signature/chunck", {
    country: regCountry,
    region: regRegion,
    district: regDistrict,
    page,
    sortBy,
    sortByDirection: parseInt(sortByDirection)
  });
}

export function getChunkRegion({
  country: regCountry,
  region: regRegion,
  district: regDistrict,
  sortBy,
  sortByDirection
}) {
  return db_post("/signature/chunckregion", {
    country: regCountry,
    region: regRegion,
    district: regDistrict,
    sortBy,
    sortByDirection: parseInt(sortByDirection)
  });
}

//export function initCountry() {
//  area, truth;
//  if (area !== "country") return db_get(`/signature/init/}`);
//  return db_get("/signature/init");
//}

//"/signature/init/district/Texas"

//const { truth } = req.params;

export async function initRegion(truth) {
  return await db_get(`/signature/init/district/${truth}`);
}
export const initThisCountry = async truth => {
  return db_get("/signature/init/country/" + truth);
};
export const initThisDistrict = async truth => {
  return db_get("/signature/init/district/" + truth);
};
export const initThisRegion = async truth => {
  return db_get("/signature/init/region/" + truth);
};
export default {
  searchSignatureCollection,
  getResortFromId
};
