import React, { useReducer, useEffect, useContext, useState } from "react";
import {
  getRegCount,
  getRegOptions,
  getChunk,
  initDistrict
} from "../../services/signatureService";
import TbdBreadcrumbHeader from "../../tbdCommon/TbdBreadcrumbHeader";
import TbdSignatureSortToolsHeader from "../../tbdPageComponents/tbdSignaturePage/TbdSignatureSortToolsHeader";
import { SignatureContext, TbdContext } from "../../data/TbdContext";
import queryString from "query-string";
import TbdPagination from "../../tbdCommon/TbdPagination";
import TbdParallaxBanner from "../../tbdPageComponents/tbdSignaturePage/TbdParallaxBanner";
//import TbdRegistryAreaHeader from "../../tbdPageComponents/TbdRegistryAreaHeader";
import TbdRegistrySidebarSearch from "../../tbdPageComponents/TbdRegistrySidebarSearch";
import TbdRegistryResort from "../../tbdPageComponents/TbdRegistryResort";
import { Link } from "react-router-dom";

const TbdRegistry = props => {
  const [sortBy, setSortBy] = useState(1);
  const [sortByDirection, setSortByDirection] = useState(1);
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState(null);
  const [showAbleData, setShowAbleData] = useState(null);
  const [country, setCountry] = useState("ALL");
  const [region, setRegion] = useState("ALL");
  const [district, setDistrict] = useState("ALL");
  const [count, setCount] = useState(1);
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [store, setStore] = useState([]);
  const [values, setValues] = useState([]);
  const [focus, setFocus] = useState("country");

  //REGISTRY
  useEffect(() => {
    getChunk({ country, region, district, page })
      .then(newPageData => {
        setPageData(newPageData);
        return newPageData;
      })
      .then(result => {
        getRegCount({ country, region, district }).then(setCount);
        setShowAbleData(result);
        console.log(count);
        return count;
      })
      .then(count => {
        getRegOptions({ country, region, district }).then(opts => {
          setValues(opts.values);
          console.log(opts.values);
          setCountries(opts.values);
        });
      });
  }, [count, country, district, page, region]);

  useEffect(() => {
    getRegOptions({ country, region, district }).then(opts => {
      if (country === focus) setCountries(opts.values);
      if (region === focus) setRegions(opts.values);
      else setDistricts(opts.values);
    });
  }, [country, district, focus, region]);

  return (
    <SignatureContext.Provider value={{ page }}>
      <TbdParallaxBanner />

      <main>
        <TbdBreadcrumbHeader />
        <div className="collapse" id="collapseMap">
          <div id="map" className="map"></div>
        </div>

        <div className="container margin_60">
          <div className="row">
            <TbdRegistrySidebarSearch
              setCountry={setCountry}
              setRegion={setRegion}
              setRegions={setRegions}
              setDistric={setDistrict}
              country={country}
              countries={countries}
              regions={regions}
              districts={districts}
            />
            <div className="col-lg-9">
              <TbdSignatureSortToolsHeader
                countries={countries}
                regions={regions}
                districts={districts}
                sortBy={sortBy}
                sortByDirection={sortByDirection}
              />
              <TbdPagination />

              <div class="main_title">
                <h2 className>
                  {props.reaId} <span>ALL</span> Registry
                </h2>
                <p></p>
                <h4>
                  {count} - <span>Resorts</span>
                </h4>
                <p></p>
                <Link href={`/signature/${props._id}`} class="btn_1 white">
                  Search Signature Collsection
                </Link>
              </div>

              {pageData && <TbdRegistryResort resorts={pageData} />}

              <TbdPagination />
            </div>
          </div>
        </div>
      </main>
    </SignatureContext.Provider>
  );
};

export default TbdRegistry;
