import React, { useContext, useEffect, useRef } from "react";
import { TbdContext } from "../data/TbdContext";

const TbdSearchMenu = () => {
  const { searchIsOpen, tbdDispatch } = useContext(TbdContext);
  const searchRef = useRef(null);
  const inputRef = useRef(null);
  useEffect(() => {
    if (searchIsOpen === true) console.log(inputRef.current); //inputRef.current.focus();
  }, [searchIsOpen]);

  return (
    <div
      ref={searchRef}
      className={`search-overlay-menu ${searchIsOpen === true ? "open" : ""}`}
    >
      <span
        className="search-overlay-close"
        onClick={() => tbdDispatch({ type: "toggleSearch" })}
      >
        <i className="icon_set_1_icon-77"></i>
      </span>
      <form role="search" id="searchform" method="get">
        <input ref={inputRef} name="q" type="search" placeholder="Search..." />
        <button type="submit">
          <i className="icon_set_1_icon-78"></i>
        </button>
      </form>
    </div>
  );
};

export default TbdSearchMenu;
