import React, { useContext } from "react";
import { Input } from "reactstrap";
//import { SignatureContext } from "../../data/TbdContext";

import DistrictCheckBox from "../../tbdViews/signature/DistrictCheckBox";

const TbdRegistrySidebarSearch = props => {
  //  const {
  //    month,
  //    country,
  //    useSearcher,
  //    destinations,
  //    regions,
  //    region,
  //    districts,
  //    signatureDispatch
  //  } = useContext(SignatureContext);

  return (
    <aside class="col-lg-3">
      {/** 
      <p>
        <a
          class="btn_map"
          data-toggle="collapse"
          href="#collapseMap"
          aria-expanded="false"
          aria-controls="collapseMap"
          data-text-swap="Hide map"
          data-text-original="View on map"
        >
          View on map
        </a>
      </p>
*/}
      <div id="filters_col">
        <a
          data-toggle="collapse"
          href="#collapseFilters"
          aria-expanded="false"
          aria-controls="collapseFilters"
          id="filters_col_bt"
        >
          <i class="icon_set_1_icon-65"></i>Filters
        </a>
        <div class="collapse show" id="collapseFilters">
          <div class="filter_type">
            <h6>Location</h6>
            <div class="form-group">
              <label>Preferred travel area</label>
              <Input
                type="select"
                placeholder={"FILTER BY LOCATION"}
                value={props.country}
                onChange={e => {
                  props.setCookie("country", e.target.value, {
                    path: "/registry"
                  });
                  props.setCountry(e.target.value);
                }}
              >
                {props.countries.map(country => {
                  return (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  );
                })}
                <option value={"ALL"}>ALL</option>
              </Input>
            </div>
          </div>

          <div class="filter_type">
            <h6>Region</h6>
            <ul>
              {props.country !== "ALL" && (
                <li>
                  <div class="form-group">
                    <label>Preferred travel area</label>
                    <Input
                      type="select"
                      onChange={e => {
                        props.setCookie("region", e.target.value, {
                          path: "/registry"
                        });
                        props.setRegion(e.target.value);
                      }}
                      value={props.region}
                      placeholder={"FILTER BY REGION"}
                    >
                      {props.regions.map(region => {
                        return (
                          <option key={region} value={region}>
                            {region}
                          </option>
                        );
                      })}
                      <option value={"ALL"}>ALL</option>
                    </Input>
                  </div>
                </li>
              )}
            </ul>
          </div>

          <div class="filter_type">
            <h6>Distric</h6>
            {props.districts && (
              <ul>
                {props.districts.map(district => {
                  return (
                    <li key={district}>
                      <DistrictCheckBox
                        name={district}
                        handleDistrictChange={props.handleDistrictChange}
                        //dispatch={signatureDispatch}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>

      <div class="box_style_2">
        <i class="icon_set_1_icon-57"></i>
        <h4>
          Need <span>Help?</span>
        </h4>
        <a href="tel://17739853001" class="phone">
          (773) 985-3001
        </a>
        <small>Monday to Friday 9.00am - 7.30pm</small>
      </div>
    </aside>
  );
};

export default TbdRegistrySidebarSearch;
