import React from "react";
import moment from "moment";
import paymentsImg from "../assets/img/payments.png";

const TbdFooter = () => {
  return (
    <footer className="revealed">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <h3>Need help?</h3>
            <a href="tel://7739853001" id="phone">
              (773) 985-3001
            </a>
            <a href="mailto:booking@tbdportal.com" id="email_footer">
              booking@tbdportal.com
            </a>
            <p>
              <img
                src={paymentsImg}
                width="231"
                height="30"
                alt="Payment Options"
                data-retina="true"
                className="img-fluid"
              ></img>
            </p>
          </div>
          <div className="col-lg-2 col-md-3 ml-md-auto">
            <h3>About</h3>
            <ul>
              <li>
                <a href="#">About us</a>
              </li>
              <li>
                <a href="#">FAQ</a>
              </li>
              <li>
                <a href="#">Blog</a>
              </li>
              <li>
                <a href="#">Contacts</a>
              </li>
              <li>
                <a href="#">Login</a>
              </li>
              <li>
                <a href="#">Register</a>
              </li>
              <li>
                <a href="#">Terms and condition</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-4" id="newsletter">
            <h3>Newsletter</h3>
            <p>Join our newsletter to stay informed about offers and news.</p>
            <div id="message-newsletter_2"></div>
            <form
              method="post"
              action="assets/newsletter.php"
              name="newsletter_2"
              id="newsletter_2"
            >
              <div className="form-group">
                <input
                  name="email_newsletter_2"
                  id="email_newsletter_2"
                  type="email"
                  placeholder="Your mail"
                  className="form-control"
                ></input>
              </div>
              <input
                type="submit"
                className="btn_1"
                id="submit-newsletter_2"
              ></input>
            </form>
          </div>
          <div className="col-lg-2 ml-lg-auto">
            <h3>Settings</h3>
            <div className="styled-select">
              <select name="lang" id="lang">
                <option value="English">English</option>
                <option value="French">French</option>
                <option value="Spanish">Spanish</option>
                <option value="Russian">Russian</option>
              </select>
            </div>
            <div className="styled-select">
              <select name="currency" id="currency">
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
                <option value="RUB">RUB</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div id="social_footer">
              <ul>
                <li>
                  <a href="#">
                    <i className="icon-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="icon-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="icon-google"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="icon-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="icon-pinterest"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="icon-vimeo"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="icon-youtube-play"></i>
                  </a>
                </li>
              </ul>
              <p>© Travel By Design {moment().format("YYYY")}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default TbdFooter;
