import React from "react";
import LazyHero from "react-lazy-hero";
import HeadImg from "../../assets/head3.jpg";

const TbdRegistryBanner = () => {
  return (
    <LazyHero imageSrc={HeadImg} isFixed minHeight={"600px"} opacity={0}>
      <div className="parallax-content-2">
        <div className="container">
          <div className="row">
            <div className="col" style={{ textAlign: "right" }}>
              <h1 className="animated fadeInLeft">
                Registry <span style={{ fontSize: "large" }}>Collection</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </LazyHero>
  );
};

export default TbdRegistryBanner;
